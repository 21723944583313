export const environmentPreProd = {
    apiUrl: {
      baseApiUrl: "https://emeapprd.chubbdigital.com/claims.vendormanagement",
      baseApiUrlAssignment: "https://emeapprd.chubbdigital.com/claims.vendorassignment",
      baseApiUrlCommonRef: "https://nauat.chubbdigital.com/claims.commonreference",
      baseApiUrlAppCommonRef: "https://emeapprd.chubbdigital.com/cog.claims.cogcommonreference",
      baseApiUrlDocMgnt: "https://emeapprd.chubbdigital.com/claims.cogdocumentmanagement",
      baseApiUrlVendorAdb2c: "https://nauat.chubbdigital.com",
      newVendorRegistrationURL: "https://vendorassignment-preprod.chubb.com/vendorlogin/registration/organization-name?ui_locales={{**}}"
    },
    authProviderConfig: {
      authProvider: "MSAL2WithEvents",
      tenant: "auth.uat.chubb.com",
      clientId: "bd440017-c4cc-4e4a-9238-cd3ae4123d3d",
      policy: "B2C_1A_COGVENDOR_SIGNIN",
      redirectUrl: "https://vendorassignment-preprod.chubb.com",
      scopes: [
        {
          scope: "https://uatb2c.chubbdigital.com/COGVendorAssignmentAPI/write"
        },
        {
          scope: "https://uatb2c.chubbdigital.com/COGVendorAssignmentAPI/read"
        }
      ],
      queryParameters: [
        {
          key: "domain_hint",
          value: "CHUBBgroup"
        }
      ]
    },
    jwt: {
      common: {
        url: "https://nauat08.chubbdigital.com/api/tokens?Identity=AAD",
        apP_ID: "58e3739b-8e43-45e4-8470-c0470c353d63",
        apP_KEY: "4P_.m6H7uW5~KP-p94kqOnuSqX-.c2kS~m",
        resource: "ffaa933e-38a3-48d4-b0f2-ed4a75234829",
        ocpApimSubscriptionKey: "2e47dbd8ea444916848cf838e15e116c",
        apiVersion: "2"
      },
      cogcommon: {
        url: "https://emeapprd.chubbdigital.com/api/tokens/?Identity=AAD",
        apP_ID: "2eea24a3-43a2-4616-9400-5d84ad13eed8",
        apP_KEY: "z3ez~Rm-I2_bcg2GzdGrSJ_J0KIi0rDO7N",
        resource: "5798bd97-d701-4189-a848-1bd0fd895701",
        ocpApimSubscriptionKey: "5d035f4a260d47efa173522e3e33d7b3",
        apiVersion: "1"
      },
      cogvendormanager: {
        url: "https://emeapprd.chubbdigital.com/api/tokens/?Identity=AAD",
        apP_ID: "73fe19b9-d01f-4dbc-aaa4-b1d589ab095c",
        apP_KEY: "9~ENxaODoi.8hZ3x9BFp7v-JrA.H-72-_7",
        resource: "5b3dd903-d640-4e38-b34a-32323fe6fb5d",
        ocpApimSubscriptionKey: "ba417139356843118c326aaa1e072bec",
        apiVersion: "1"
      },
      cogvendorassignment: {
        url: "https://emeapprd.chubbdigital.com/api/tokens/?Identity=AAD",
        apP_ID: "5c40dac4-cb09-4659-9172-d0b9459d4179",
        apP_KEY: "OJa9B..Pw_-w5~idPIf5cpC3i6Isk-S9NK",
        resource: "99f45297-a08c-4111-9f10-58610eb78111",
        ocpApimSubscriptionKey: "0730b344f9474974aae5cb42fa9e7842",
        apiVersion: "1"
      },
      cogdocumentmanagement: {
        url: "https://emeapprd.chubbdigital.com/api/tokens/?Identity=AAD",
        apP_ID: "27123e1e-58b8-4ca5-8278-a90daaacc062",
        apP_KEY: "Uh.jG5.m--A0Ag~8_.kupoDEYuF30SB383",
        resource: "28da46e4-eb12-41e3-9b6d-99a7d8084283",
        ocpApimSubscriptionKey: "64084e9725cb40fe82ea11a2504c7e6a",
        apiVersion: "1"
      },
      cogadb2cvendor: {
        url: "",
        apP_ID: "",
        apP_KEY: "",
        resource: "",
        ocpApimSubscriptionKey: "cec17954db2e406d80813185f8e610bc",
        apiVersion: ""
      },
      cogadb2adgroup: {
        url: "",
        apP_ID: "",
        apP_KEY: "",
        resource: "",
        ocpApimSubscriptionKey: "cec17954db2e406d80813185f8e610bc",
        apiVersion: ""
      }
    },
    appConstants: {
      parsingTeamEmail: "tanuja.naik@chubb.com"
    }
  }
  