// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody tr:nth-child(odd) td {
    background-color: #fcf4ff !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInZlbmRvci1iaWxsaW5nLW1ldGFkYXRhLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7SUFDSSxvQ0FBb0M7QUFDeEMiLCJmaWxlIjoidmVuZG9yLWJpbGxpbmctbWV0YWRhdGEuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbInRib2R5IHRyOm50aC1jaGlsZChvZGQpIHRkIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmNmNGZmICFpbXBvcnRhbnQ7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/_components/vendor-billing-metadata/vendor-billing-metadata.component.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;AACA,wYAAwY","sourcesContent":["tbody tr:nth-child(odd) td {\n    background-color: #fcf4ff !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
