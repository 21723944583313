import { VendorAssignmentAcceptanceType } from './enums/app.enum';
import { IApiResponse } from './iapi-response';

export interface IVendorAcceptReqObj {
  vendorAssignmentId: string;
  leadTimeKeeperPhone: string;
  vendorActivity: VendorAssignmentAcceptanceType;
  leadTimeKeeper: string;
  vendorFileRef: string;
  manualVendorId: string;
  lastModifiedBy: string;
}

export interface IVendorDeclineReqObj {
  vendorAssignmentId: string;
  declineReasonCode: string;
  otherComments: string;
  vendorActivity: VendorAssignmentAcceptanceType;
  lastModifiedBy: string;
}

export interface IVendorAssignmentResObj extends IApiResponse {
  returnMessage: string;
  statusCode: boolean;
  vendorAssignmentId: string;
  vendorAssignmentStatus: string;
}

export interface IVendorAssignmentValidReqObj {
  vendorAssignmentId: string;
  vendorRateCardId: string;
  vendorAssignmentType: string;
  emailId: string;
  commercialVendorName: string;
  localeID: string;
}
