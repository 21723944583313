import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {BlockService} from '../../block/block.service';
import {catchError, map} from 'rxjs/operators';
import {ApiUrls} from '../services';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor (private readonly loader: BlockService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request && request.url &&
      !(request.url.match(ApiUrls.SendNotification) ||
        request.url.match(ApiUrls.ExportVendorRecords))) {
      this.addRequest(request);
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse || event instanceof HttpErrorResponse) {
            this.removeRequest(request);
          }
          return event;
        })
      ).pipe(catchError(error => {
        this.resetRequests();
        return throwError(() => error);
      }));
    } else {
      return next.handle(request).pipe(catchError(error => {
        this.resetRequests();
        return throwError(() => error);
      }));
    }
  }

  private addRequest(request: HttpRequest<any>) {

    if (this.requests.length==1) {
      if (request.url.match(ApiUrls.VendorApproval)) {
        this.requests = [];
        this.requests.push(request);
        this.loader.isLoading.next(this.requests.length > 0);
      }
    }
    else {
      this.requests.push(request);
      this.loader.isLoading.next(this.requests.length > 0);
    }

  }

  private removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loader.isLoading.next(this.requests.length > 0);
  }

  private resetRequests() {
    this.requests = [];
    this.loader.isLoading.next(false);
  }
}
