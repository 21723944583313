export interface INotificationRequest {
  AssignmentID: string;
  RecipientEmail: string;
  Language: string;
  NotificationType: string;
  NotificationName: string;
  DeepLinkUri: string;
  PasswordResetLink: string;
  Region: string;
  Country: string;
  ClaimNumber: string;
  ClaimHandlerName: string;
  CommercialVendorName: string;
}
