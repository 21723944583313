export const groupKeysProd = [
    {
      "key": "1-claims-vm-super-user",
      "value": "b4473f28-0584-4607-8642-3ee1bab6d7dd"
    },
    {
      "key": "2-claims-vm-advanced-user",
      "value": "00cff332-68c6-421b-87de-147c1b0d01f3"
    },
    {
      "key": "3-claims-vm-basic-user",
      "value": "a17f91f2-9094-4557-b831-b1577256ea4c"
    },
    {
      "key": "4-claim-user",
      "value": "aa1bde6d-3a25-401e-8502-7a96e4785d61"
    },
    {
      "key": "5-tpa-user",
      "value": "5c413de7-4b8b-4f25-8cd7-e3c32f979d7f"
    },
    {
      "key": "6-parsing-team-user",
      "value": "8c11799e-b7de-4547-90fc-01c8a38ee329"
    },
    {
      "key": "7-non-claims-user",
      "value": "66ea0cdd-c748-4226-b173-b7edf153cfd6"
    }
  ]
  