export interface ICodeLists {
  source: string;
  codelist: ICodeList[];
}

export interface ICodeList {
  code: string;
  name: string;
  description: string;
  categoryname: string;
  sortOrder?: number;
}

export interface ICrossWalksResp {
  source: string;
  target: string;
  crossWalkList: [
    {
      sourceCode: string;
      sourceValue: string;
      sourceDescription: string;
      targetCode: string;
      targetValue: string;
      targetDescription: string;
      categoryName: string
    }
  ];
  localId: string
}

