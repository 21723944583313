import { IApiResponse } from "./iapi-response";
import { IVendor } from "./ivendor";
//used for vendor search results
export interface IAssignmentSearchResponse extends IVendor, IApiResponse {
  uniqueCommercialVendorReference: string;
  uniqueGroupReportingVendorReference: string;
}

export interface ISourceToTargetCode {
  mLOBId: string;
  sourceCode: string;
  sourceCode1: string;
}

export interface ISourceToTargetCodeRes {
  targetCodeValue: string;
}

export interface IPrefLanguageRes {
  vendorPreferedLanguage: string;
}
export interface IDefaultCurrcyCod {
  currencyCode: string;
}

