import { CommonModule } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { APP_ID, APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtHelperService, JwtModule } from "@auth0/angular-jwt";
import { ChubbAuthMsal2PkceModule } from "@chubb-auth/msal2-pkce";
import { Adb2cUtilsModule } from "@chubb-auth/types";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BlockModule } from "./block/block.module";
import AuthGuard from "./core/guard/authGuard";
import {
  CodeDecodeInterceptor,
  CogCommonRefInterceptor,
  CogDocumentMgmntInterceptor,
  CogVendorAssignInterceptor,
  CogVendorManageInterceptor,
  LoaderInterceptor,
  RefreshAdb2cTokenInterceptor,
} from "./core/interceptors";
import {
  AuthProviderService,
  BroadcastEventService,
  CogErrorHandlerService,
  CommonrefDataService,
  ConfigDataService,
  initConfigLoad,
  NotificationService,
  VendorAdb2cService,
} from "./core/services";
import { SharedModule } from "./shared/shared.module";
import { ChHeaderComponent } from './marina-design/ch-header/ch-header.component';
import { ChFooterComponent } from './marina-design/ch-footer/ch-footer.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BlockModule,
    SharedModule,
    JwtModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // authentication
    Adb2cUtilsModule,
    ChubbAuthMsal2PkceModule,
    ChHeaderComponent,
    ChFooterComponent
  ],
  providers: [
    {
      provide: APP_ID, 
      useValue: 'ng-cli-universal'
    },
    AuthProviderService,
    AuthGuard,
    ConfigDataService,
    CommonrefDataService,
    VendorAdb2cService,
    NotificationService,
    BroadcastEventService,
    JwtHelperService,
    { provide: ErrorHandler, useClass: CogErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CodeDecodeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CogCommonRefInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CogVendorManageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CogVendorAssignInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CogDocumentMgmntInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshAdb2cTokenInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfigLoad,
      deps: [ConfigDataService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
