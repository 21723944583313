import {VendorAssignmentType} from './enums/app.enum';

export interface IVendorAssignmentReq {
  vendorAssignmentId : string;
  claimNumber : string;
  assignmentStartDate : string;
  assignmentEndDate : string;
}

export interface IVendorAssignmentRes {
  claimNumber : string;
  chubbClaimsAdjuster : string;
  chubbAdjusterTelephoneNumber : string;
  chubbInstructingOffice : string;
  claimID : string;
  claimRegion : string;
  claimCountry : string;
  dateOfLoss : string;
  lossDescription : string;
  lossCountry : string;
  lossCity : string;
  lossState : string;
  broker : string;
  businessDependentPolicyholderName : string;
  vendorAssignmentType : VendorAssignmentType;
  vendorID : string;
  manualVendorID : string;
  vendorReportingGroupID : string;
  sourceClaimID : string;
  sourceSystem : string;
  relatedVendorRateCardID : string;
  vendorPanelID : string;
  vendorAssignmentID : string;
  insuredName : string;
  majorLineOfBusiness : string;
  policyNumber : string;
  policyEffectiveDate : string;
  policyExpirationDate : string;
  causeOfLoss : string;
  dateReported : string;
  initialReserveAmount : string;
  cATCode : string;
  claimSensitivity : string;
  groupReportingVendorName : string;
  commercialVendorName : string;
  uniqueVendorReference : string;
  region : string;
  country : string;
  countryPanelName : string;
  primaryVendorCategory : string;
  vendorServiceCategory : string;
  vendorMajorLineOfBusiness : string;
  vendorMinorLineOfBusiness : string;
  vendorType : string;
  status : string;
  panelSegmentation : string;
  nicheArea : string;
  vendorPanelDescription : string;
  representationType : string;
  vendorStateRegionCoverage : string;
  vendorCityCoverage : string;
  localOrTravelGeographicCoverage : string;
  t360Billing : string;
  t360PanelName : string;
  CATAgreement : string;
  commercialVendorStatus : string;
  commercialVendorStatusValidFrom : string;
  commercialVendorStatusValidUntil : string;
  currencyCode : string;
  assignmentNumber : string;
  assignmentPreferredLanguage : string;
  assignmentType : string;
  assignmentReasonCode : string;
  vendorAssignmentStatus : string;
  assignmentCreationDate : string;
  percentageOfResponsibility : string;
  isAssignmentSensitive : false;
  leadTimekeeper : string;
  leadTimekeeperPhone : string;
  otherComments : string;
  uniqueReferenceID : string;
  vendorInstructions : string;
  isRateCardPricingAttach : false;
}
