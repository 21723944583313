import { VendorAssignmentType } from './enums/app.enum';
import { IVendor } from './ivendor';

export interface IAssignment {
  vendorInformation: IPanelVendorInfo;
  otherInformation: IAssignmentOtherInfo;
  vendorAssignmentInstructions: IVendorAssignInstruction;
  vendorPricingRateCardCheck: boolean;
  vendorAssignmentType: VendorAssignmentType;
}

export interface IPanelVendorInfo {
  commercialVendorName: string;
  vendorContactName: string;
  vendorEmailId: string;
  conflictOfInterestCheck: boolean;
  manualPrimaryVendorCategory: string;
  manualvendorServiceCategory: string;
  manualmajorLineOfBusiness: string;
  vendorRegion: string;
  vendorCountry: string;
  vendorType: string;
  availableVendorInformation: IVendor;
}

export interface IAssignmentOtherInfo {
  instructionCode: string;
  t360InstructionCode: string;
  t360SpecialPaymentInstructions: string;
  percentageOfResponsibility: string;
  offPanelVendorReason: string;
  deductiblePolicyExcess: string;
  initialReserveClaimedAmount: string,
  deductiblePolicyExcessCurr: string,
  initialReserveClaimedAmountCurr: string
}

export interface IVendorAssignInstruction {
  isAssignmentConfidential: boolean;
  leadTimeKeeperOrAssignmentOwnerEmailAddress: string;
  representationType: string;
  inputVendorInstructions: string
}
