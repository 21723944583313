import { Component, Input, OnInit } from '@angular/core';
import { AuthProviderService } from '../../../core/services';
import {IAuthUser, IVendorBillingMetaData} from '../../../models';
@Component({
  selector: 'app-vendor-billing-metadata',
  templateUrl: './vendor-billing-metadata.component.html',
  styleUrls: ['./vendor-billing-metadata.component.css']
})
export class VendorBillingMetadataComponent implements OnInit {

  @Input() vendorbillingmeta : IVendorBillingMetaData;
  // auth user
  user: IAuthUser;

  constructor(private readonly authServ: AuthProviderService) { }

  ngOnInit(): void {
    this.authServ.getAuthUser().subscribe(u => {
      this.user = u;
    });
  }

}
