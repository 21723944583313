import jsonata from "jsonata";
import { isNil } from "lodash-es";

export class JsonPathUtils {
  getValue<T>(rawJson: any, jsonPath: string): T {
    const expression = jsonata(jsonPath);
    return expression.evaluate(rawJson) as T;
  }

  getJsonPart(rawJson: any, jsonPath: string): Promise<string> {
    const expression = jsonata(`$string(${jsonPath})`);
    return expression.evaluate(rawJson);
  }

  getBooleanValue(rawJson: any, jsonPath: string): boolean {
    const value = this.getValue<string>(rawJson, jsonPath);
    if (isNil(value)) {
      return false;
    }
    else {
      if (typeof value === "boolean") {
        return value;
      }
      else
       return value.toLowerCase() === "true";
    }
  }

  getNumericValue(rawJson: any, jsonPath: string): number {
    const value = this.getValue<string>(rawJson, jsonPath);
    return +value;
  }

  getDateValue(rawJson: any, jsonPath: string): Date {
    const value = this.getValue<string>(rawJson, jsonPath);
    return new Date(value);
  }

  async getArray(rawJson: any, jsonPath: string): Promise<any> {
    const expression = jsonata(jsonPath);
    const length = this.getValue(rawJson, `\$count(${jsonPath})`);
    if (Number(length) > 1) {
      return expression.evaluate(rawJson) as unknown as Promise<any>;
    } else if (length === 1) {
      const valueArray = new Array<any>();
      valueArray.push(await expression.evaluate(rawJson));
      return valueArray as unknown as Promise<any>;
    } else {
      return new Array<any>();
    }
  }
}
