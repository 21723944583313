import { KeyValue } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Adb2cConfig,
  Adb2cState,
  AuthProvider,
  AuthProviderFactory,
  ResponseMode,
  StorageLocation,
} from "@chubb-auth/types";
import { Observable, ReplaySubject } from "rxjs";
import { appPermissions } from "src/environments/environment.app-permissions";
import { groupKeys } from "src/environments/environment.group-keys";
import { groupKeysProd } from "src/environments/environment.group-keys-prod";
import { environmentUrls } from "../../../environments/environment.urls";
import {
  IAdb2cGetADGroupResps,
  IAuthProviderConfig,
  IAuthUser,
  IAuthUserPermission,
} from "../../models";
import { AppConstants } from "../constants/app.const";
import { JsonPathUtils } from "../utils/json-path-utils";
import { ApiUrls } from "./api-urls";
import { ConfigDataService } from "./config-data.service";
export const GRAPH_ENDPOINT = "https://graph.microsoft.com/oidc/userinfo";

@Injectable({
  providedIn: "root",
})
export class AuthProviderService {
  currentConfig: IAuthProviderConfig;
  private _currentUser: ReplaySubject<IAuthUser> = new ReplaySubject<IAuthUser>(
    1
  );

  constructor(
    private readonly http: HttpClient,
    private readonly config: ConfigDataService,
    private readonly providerFactory: AuthProviderFactory
  ) {}

  // gets auth provider for multiple login features
  getAuthProvider(): AuthProvider {
    let _config = this.config.getConfig();
    this.currentConfig = _config.authProviderConfig;
    const authProvider = this.providerFactory.getProvider(
      this.currentConfig.authProvider
    );
    authProvider.initialize(this.convertToAdb2cConfig(this.currentConfig));
    return authProvider;
  }

  // gets user claims/details
  getAuthUser() {
    return this._currentUser.asObservable();
  }

  // sets the auth user after login redirect
  // and get the user state
  setAuthUser(state: Adb2cState): Observable<IAuthUser> {
    let u = this.convertToUserClaims(state);
    if (u.adgroups && u.adgroups.length > 0) {
      this.setPermission(u);
    } else {
      this.getADGroups(u.email).subscribe((g) => {
        u.adgroups =
          g?.value?.length > 0 ? g.value[0].groups : [];
        this.setPermission(u);
      });
    }
    return this._currentUser.asObservable();
  }

  setControlVisibility(u: IAuthUser, ctrl: string) {
    return u.permissions["va-ctrl-hide-role"].findIndex((c) => c === ctrl) <= -1;
  }

  private setPermission(u: IAuthUser) {
    const urls = environmentUrls.urls;
    const hostname = window?.location?.hostname;
    let groupKey = "";
    let r = [];
    let key: string = "";
    let jsonUtil = new JsonPathUtils();
    let gks = new RegExp(urls.prod).test(hostname)
      ? <KeyValue<string, string>[]>groupKeysProd
      : <KeyValue<string, string>[]>groupKeys;
    r = gks
      .filter((v, i, a) => u.adgroups.indexOf(v.value) > -1)
      .sort((a, b) => a.key.localeCompare(b.key));
    if (r.length > 0) {
      key = r[0].key;
    }
    if (!key && u.lanid) {
      key = ""; // unauth
    } else if (!key && !u.lanid) {
      // vendor
      key = "external";
      u.lanid = u.email;
    }
    switch (key) {
      case "1-claims-vm-super-user":
        u.role = "claims-vm-super-user";
        groupKey = "claimsvmsuperuser";
        break;
      case "2-claims-vm-advanced-user":
        u.role = "claims-vm-advanced-user";
        groupKey = "claimsvmadvanceduser";
        break;
      case "3-claims-vm-basic-user":
        u.role = "claims-vm-basic-user";
        groupKey = "claimsvmbasicuser";
        break;
      case "4-claim-user":
        u.role = "claim-user";
        groupKey = "claimuser";
        break;
      case "5-tpa-user":
        u.role = "tpa-user";
        groupKey = "tpauser";
        break;
      case "6-parsing-team-user":
        u.role = "parsing-team-user";
        groupKey = "parsingteamuser";
        break;
      case "7-non-claims-user":
        u.role = "non-claims-user";
        groupKey = "nonclaimsuser";
        break;
      case "external":
        u.role = "external";
        groupKey = "external";
        break;
      default:
        u.role = null;
        groupKey = "default";
        break;
    }
    u.permissions = jsonUtil.getValue<IAuthUserPermission>(
      appPermissions,
      groupKey
    );
    this._currentUser.next(u);
  }

  private convertToUserClaims(state: Adb2cState): IAuthUser {
    let uc: IAuthUser = <IAuthUser>{};
    if (state?.userClaims) {
      let claims = state.userClaims;
      uc = {
        isLoggedIn: state.isLoggedIn,
        lanid: claims.LanID,
        email: claims.email ?? "",
        name: claims.name ?? "",
        family_name: claims.family_name ?? "",
        given_name: claims.given_name ?? "",
        idp: claims.idp ?? "",
        adgroups: claims.adgroups,
        organizationName: claims.extension_GroupName ?? "",
      };
    }
    return uc;
  }

  private convertToAdb2cConfig(c: IAuthProviderConfig): Adb2cConfig {
    return <Adb2cConfig>{
      stateKey: AppConstants.STATE_KEY_ADB2C_CONFIG,
      tenant: c.tenant,
      policy: c.policy,
      clientId: c.clientId,
      redirectUri: c.redirectUrl,
      stateLocation: StorageLocation.Session,
      scopes: c.scopes ? c.scopes.map((_) => _.scope) : undefined,
      responseMode: ResponseMode.Fragment,
      enableLogging: false,
    };
  }

  private getADGroups(email: string) {
    let _config = this.config.getConfig();
    let h = new HttpHeaders().set(
      "Ocp-Apim-Subscription-Key",
      _config.jwt.cogadb2adgroup.ocpApimSubscriptionKey
    );
    return this.http.get<IAdb2cGetADGroupResps>(
      `${
        _config.apiUrl.baseApiUrlVendorAdb2c
      }/${ApiUrls.SearchByEmailADGroup.replace("{**}", email)}`,
      { headers: h }
    );
  }
}
