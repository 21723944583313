// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environmentUrls = {
  production: false,
  urls: {
    local: 'localhost',
    dev: 'cogclaimsvendormanageassignweb-dev.azurewebsites.net',
    sit: 'vendorassignment-sit.chubb.com',
    uat: 'vendorassignment-uat.chubb.com',
    perf: 'vendorassignment-preprod.chubb.com',
    prod: 'vendorassignment.chubb.com'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
