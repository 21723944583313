import { TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

// Components
import { DialogComponent } from '../components/dialog/dialog.component';

type DialogRef<T> = MatDialogRef<DialogComponent<T>>;

export class DialogService<T = undefined> {
  opened$ = this.dialogRef.afterOpened().pipe(first());

  constructor(private dialogRef: DialogRef<T>) { }

  get context() {
    return this.dialogRef.componentInstance.data.context;
  }

  close() {
    this.dialogRef.close();
  }

  setHeaderText(headerText: string) {
    this.dialogRef.componentInstance.data.headerText = headerText;
    return this;
  }

  setTemplate(template: TemplateRef<any>) {
    this.dialogRef.componentInstance.data.template = template;
    return this;
  }

  setMessage(message: string) {
    this.dialogRef.componentInstance.data.message = message;
    return this;
  }

  setTemplateFlag(flag: boolean) {
    this.dialogRef.componentInstance.data.isLocalTemplate = flag;
    return this;
  }

  setErrorTemplateFlag(flag: boolean) {
    this.dialogRef.componentInstance.data.isErrorTemplate = flag;
    return this;
  }
}
