export class ApiUrls {
  // corporate common reference Api
  public static readonly GetCodeLists = 'claims/reference/codelists';
  public static readonly GetCoprDepandantCodeLists = 'claims/reference/dependant-codelists';
  public static readonly GetApplicationCodeLists = 'claims/reference/codelists';

  // cog common ref api
  public static readonly GetExpCodeLists = 'claims/reference/uxCwCdDcdLst';
  public static readonly GetDepandantCodeLists = 'claims/reference/uxCwDepLst';

  // cross walks
  public static readonly GetCrossWalks = 'claims/reference/crosswalks';

  // Load vendor country
  public static readonly LoadVendorCountry = 'claims/vendormanage/countrycodes';
  // load auto complete text box for vendor names
  public static readonly LoadVendorNames = 'claims/vendormanage/commvendornames';
  //vendor panel desc
  public static readonly getVendorPanelDesc = 'claims/vendormanage/vendorpaneldesc';
  // Representation field
  public static readonly getRepresentationField = 'claims/vendormanage/vendorreptype';

  // search assignment
  public static readonly getClaimHandlers = 'claims/vendormanage/userlanids';

  // Claim manager vendor search
  public static readonly GetVendorRecords = 'claims/vendormanage/vendorsearch';
  // Claim manager export vendor search
  public static readonly ExportVendorRecords = 'claims/vendormanage/vendorsearchExp';
  // View vendor
  public static readonly ViewVendor = 'claims/vendormanage/vendordetails';
  // Add vendor
  public static readonly AddVendor = 'claims/vendormanage/addvendor';
  // Edit/Update vendor
  public static readonly EditVendor = 'claims/vendormanage/{UniqueReferenceID}/editvendor';
  // Expire vendor
  public static readonly ExpireVendor = 'claims/vendormanage/{UniqueReferenceID}/expirevendor';
  // Suspend vendor
  public static readonly SuspendVendor = 'claims/vendormanage/{UniqueReferenceID}/suspendvendor';


  // Vendor identification
  public static readonly VendorIdentification = 'claims/manageassignmnt/vendoridentify';
  // Vendor identification
  public static readonly RulSrcTarCode = 'claims/manageassignmnt/rulSrcTarCode';
  //GetPreferredLanguage
  public static readonly GetPreferredLanguage = 'claims/manageassignmnt/vendorPreLan'
  //GetTORDocuments
  public static readonly GetTORDocuments = 'claims/manageassignmnt/TORDocList?Region={**}  '
  // get vendor service category
  public static readonly GetVendorServiceCategory = 'claims/manageassignmnt/vendorservicecg';
  // get vendor type
  public static readonly GetVendorType = 'claims/manageassignmnt/vendorType';
  // Create Assignment
  public static readonly CreateAssignment = 'claims/manageassignmnt/addassignment';
  // Save assignment
  public static readonly SaveAssignment = 'claims/manageassignmnt/saveassignment?claimNumber=';
  // Submit/Complete assignment
  public static readonly SubmitAssignment = 'claims/manageassignmnt/completeassign';
  // review/ExcelExport
  public static readonly ExportAssignmentRecords = 'claims/manageassignmnt/dwnldasnmntxl';
  //Get rule for Instruction Code
  public static readonly GetInstructionCodeRul = 'claims/manageassignmnt/assgninscode';
  //Get rule for T360InstructionCode
  public static readonly GetT360InstructionCodeRul = 'claims/manageassignmnt/assninst360code';
  //Get rule for T360PayInstructionCode
  public static readonly GetT360PayInstructionCodeRul = 'claims/manageassignmnt/assgninspaycode';
  //GetDefaultCurrcyCod
  public static readonly GetDefaultCurrcyCod = 'claims/manageassignmnt/vendorPreCur';


  // Upload attachment
  public static readonly UploadDoc = 'claims/manageDocuments/uploadDoc?assignmentId={assignmentId}&documentType={documentType}&isScan={isScan}&userLanID={lanId}';
  // Upload retrieve claim doc to blob
  public static readonly UploadToBlob = 'claims/manageassignmnt/uploadToBlob';
  // Save TOR doc to db
  public static readonly saveTORDoc = 'claims/manageassignmnt/saveTORDoc';
  // Remove attachment
  public static readonly RemoveDoc = 'claims/manageDocuments/removeDoc?lastModifiedBy={updatedBy}';
  // Download attachment
  public static readonly DownloadDoc = 'claims/manageDocuments/downloadDoc';
  // review attachment
  public static readonly ReviewAttachment = 'claims/manageassignmnt/retrievedoc';
  // claim attachment
  public static readonly RetrieveClaimAttachment = 'claims/manageassignmnt/claimDocuments?claimNumber={UniqueReferenceID1}&region={UniqueReferenceID2}&country={UniqueReferenceID3}&lastModifiedBy={UniqueReferenceID4}';


  // Vendor Accepted
  public static readonly VendorAccepted = 'claims/manageassignmnt/{UniqueReferenceID}/acceptvendorast';
  // Vendor Rejected
  public static readonly VendorRejected = 'claims/manageassignmnt/{UniqueReferenceID}/declinevendorat';
  //Review Vendor 
  public static readonly ReviewVendor = 'claims/manageassignmnt/{UniqueReferenceID}/reviewVendor';
  // Save assignment
  public static readonly VendorApproval = 'claims/manageassignmnt/vendorapproval';
  // approve assignment
  public static readonly UpdateApprovalStatus = 'claims/manageassignmnt/venapprstatus';


  // approve assignment
  public static readonly GetApproverList = 'claims/manageassignmnt/venapprlist';
  // search assignment
  public static readonly GetAssignments = 'claims/manageassignmnt/assignmntDetail';
  // save invoice
  public static readonly SaveVendorInvoice = 'claims/manageassignmnt/saveInvoice';
  // get vendor invoice details
  public static readonly GetVendorInvoiceDetails = 'claims/manageassignmnt/{UniqueReferenceID}/invoice';

  // adb2c create vendor
  public static readonly Adb2cCreateVendor = 'api/digital/adb2cplatform/CreateVendor';
  public static readonly Adb2cSearchVendor = 'api/digital/adb2cplatform/SearchVendor';
  public static readonly SearchByEmailVendor = 'api/digital/adb2cplatform/SearchUser?email={**}';
  public static readonly SearchByEmailADGroup = 'api/digital/adb2cplatform/aduser/SearchUser?email={**}'

  // notification hub
  public static readonly SendNotification = 'claims/manageassignmnt/notificationhub';

  // billing Guidelines
  public static readonly AddBillingGuideLines = 'claims/manageassignmnt/saveBlngGdln';
  public static readonly GetBillingGuidlines = 'claims/manageassignmnt/{UniqueReferenceID}/blngGdln';

  // search vendor assignment
  public static readonly GetVendorAssignments = 'claims/manageassignmnt/asgnmntDtlVndr';
  // vendor organization mapping
  public static readonly OrganiztaionMapping = 'claims/vendormanage/organiztaionmap';

  //Vender user details
  public static readonly ManageUserDetails = 'claims/vendormanage/manageUserDtl';

  //get domain type
    public static readonly DomainType = 'claims/vendormanage/{EmailId}/UserLoginDtl';


  //activate vendor
  public static readonly ActivateUser = 'claims/vendormanage/activateUser';

  //denied vendor approval
  public static readonly DeniedUser = 'claims/manageassignmnt/ReqReactivate';

  // bulk upload
  public static readonly CheckForDuplicateVendors = 'claims/vendormanage/bulkupvndrInit';
  public static readonly AddVendorToMdm = 'claims/vendormanage/addvendortomdm';
  public static readonly BulkUploadVendors = 'claims/vendormanage/bulkupvndrfin';

  
}
