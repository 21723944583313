export enum SourceSystem {
  WorkView = "wv",
  ClaimConnect = "cc",
}

export enum VendorStatus {
  CURR = "CURR",
  EXP = "EXP",
  SUS = "SUS",
}

export enum VendorAssignmentType {
  PNL = "PNL",
  MNL = "MNL",
  ACT = "ACT",
  OFFPNL = "OFFPNL",
  BD = "BD",
}

export enum ActionType {
  Cancel = "cancel",
  Submit = "submit",
}

export enum AssignmentStatusCode {
  AssignmentApprovalGranted = "AAG",
  AssignmentApprovalRejected = "AAR",
  AssignmentIncomplete = "AIN",
  PendingApproval = "PAP",
  PendingVendorAcceptance = "PVA",
  VendorAssignmentAccepted = "VAA",
  VendorAssignmentCancelled = "VAC",
  VendorAssignmentDeclined = "VAD",
  VendorApprovalAccepted = "VA",
  VendorApprovalPending = "PVAP",
  VendorApprovalDeclined = "VD",
}

export enum AssignmentStatusDescription {
  AssignmentApprovalGranted = "Assignment Approval Granted",
  AssignmentApprovalRejected = "Assignment Approval Rejected",
  AssignmentIncomplete = "Assignment Incomplete",
  PendingApproval = "Pending Approval",
  PendingVendorAcceptance = "Pending Vendor Acceptance",
  VendorAssignmentAccepted = "Vendor Assignment Accepted",
  VendorAssignmentCancelled = "Vendor Assignment Cancelled",
  VendorAssignmentDeclined = "Vendor Assignment Declined",
  VendorApprovalAccepted = "Approved",
  VendorApprovalPending = "Pending",
  VendorApprovalDeclined = "Declined",
}

export enum NotificationName {
  CreateAssignment = "Vendor Assignment Notification",
  CancelAssignment = "Vendor Assignment Notification (Cancel)",
  EditAssignment = "Vendor Assignment Notification (Edit)",
  IntakeForm_5 = "Claim Handler Notification for Parsing Intake Form",
  IntakeForm_6 = "Parsing team Notification for Subsequent Invoice",
  IntakeForm_11 = "Claim Handler Notification for Invoice Review",
  VendorApprover = "Vendor Approver Notification",
  VendorApproverEdit = "Vendor Approver Notification (Edit)",
  VendorApproverCancel = "Vendor Approver Notification (Cancel)",
  UserRegistration = "User Registration",
  DeniedActivation = "Vendor Reactivation Denied",
}

export enum NotificationType {
  External = "External",
  Internal = "Internal",
}

export enum VendorAssignmentAcceptanceType {
  accept = "accept",
  decline = "decline",
}

export enum AttachmentType {
  Claim = "CLAIM",
  Invoice = "INVOICE",
  Vendor = "VENDOR",
  Supporting = "SUPPORT",
  TOR = "TOR",
}

export enum EventType {
  LanguageChange = "LANGUAGE_CHANGE",
}

export enum UserRoles {
  nonclaimsuser = "nonclaimsuser",
  tpauser = "tpauser",
  claimuser = "claimuser",
  parsingteamuser = "parsingteamuser",
  claimsvmbasicuser = "claimsvmbasicuser",
  claimsvmadvanceduser = "claimsvmadvanceduser",
  claimsvmsuperuser = "claimsvmsuperuser",
  external = "external",
}
