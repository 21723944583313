import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthProviderService } from '../services';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export default class AuthGuard {
  constructor (private readonly authService: AuthProviderService) {}
  async canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const source$ = this.authService.getAuthUser();
    const u = await lastValueFrom(source$);
    return u.role !== 'external';
  }
}