import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {EventType} from '../../models/enums/app.enum';

@Injectable({
  providedIn: 'root'
})
export class BroadcastEventService {
  private languageEventQueue = new Subject<BroadCastEvent<string>>();
  constructor() {
    // This is intentional
  }

  onLanguageChange() {
    return this.languageEventQueue.asObservable();
  }

  dispatch(e: BroadCastEvent<string>) {
    if (e.type == EventType.LanguageChange) {
      this.languageEventQueue.next(e);
    }
  }
}

export class BroadCastEvent<T>{
  constructor (
    public type: EventType,
    public value: T
  ) {}
}
