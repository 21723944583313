// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody tr:nth-child(odd) td {
    background-color: #f8e9e9 !important
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFzc2lnbm1lbnQtb3RoZXItaW5mb3JtYXRpb24uY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtJQUNJO0FBQ0oiLCJmaWxlIjoiYXNzaWdubWVudC1vdGhlci1pbmZvcm1hdGlvbi5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsidGJvZHkgdHI6bnRoLWNoaWxkKG9kZCkgdGQge1xuICAgIGJhY2tncm91bmQtY29sb3I6ICNmOGU5ZTkgIWltcG9ydGFudFxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/_components/assignment-other-information/assignment-other-information.component.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA,4YAA4Y","sourcesContent":["tbody tr:nth-child(odd) td {\n    background-color: #f8e9e9 !important\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
