export interface IAssignmentManualInfo {
  commercialVendorName : string;
  vendorContactName : string;
  vendorEmailId : string;
  assignmentReasonCode : string;
  assignmentReasonCodeDesc : string;
  t360ReasonCode : string;
  t360ReasonCodeDesc:string;
  specialPaymentInstructions : string;
  specialPaymentInstructionsDesc:string;
  percentageOfResponsibility : string;
  isAssignmentConfidential : boolean;
  leadTimeKeeper : string;
  representationType: string;
  representationTypeDesc: string;
  vendorInstructions : string;
  offPanelAssignmentReasonCode : string;
  offPanelAssignmentReasonCodeDesc: string;
  conflictOfInterestCheck : boolean;
  deductiblePolicyExcess : string;
  initialReserveClaimedAmount: string;
  manualMajorLineOfBusiness: string;
  manualMajorLineOfBusinessCd: string;
  manualPrimaryVendorCategory: string;
  manualPrimaryVendorCategoryCd: string;
  manualVendorCountry: string;
  manualVendorCountryCD: string;
  manualVendorRegion: string;
  manualVendorServiceCategory: string;
  manualVendorServiceCategoryCd: string;
  deductiblePolicyExcessCurr: string;
  initialReserveClaimedAmountCurr: string;
  VendorType: string;
  VendorTypeDesc: string;
  IsTOROptOut: boolean;
  TOROptOutReason: string;
}
