import { IApiErrorResponse } from '.';
import { IReviewAttachment } from './attachment';
import { IAssignmentHistory } from './iassignment-history';
import { IAssignmentManualInfo } from './iassignment-manual-info';
import { IBillingGuideLines } from './ibilling-guidelines';
import { IClaimsInformation } from './iclaims-information';
import { ICommonRequest } from './iCommonRequest';
import { IVendor } from './ivendor';
import { IVendorAcceptanceInformation, IVendorBillingFeeEarnerInformation, IVendorBillingMetaData } from './ivendor-information';

export interface ISaveAssignmentResponse {
  vendorAssignmentId: string;
  vendorRecord: IVendor;
  claimRecord: IClaimsInformation;
  manualRecord: IAssignmentManualInfo;
  vendorAssignmentType: string;
  vendorPricingRateCardCheck: boolean;
  vendorAssignmentStatus: string;
  vendorAssignmentStatusCd: string;
  attachment: IReviewAttachment[];
  assignmentHistory: IAssignmentHistory;
  billingGuidelines: IBillingGuideLines;
  vendorAcceptanceInformation: IVendorAcceptanceInformation;
  vendorBillingFeeEarnerInfo: IVendorBillingFeeEarnerInformation[];
  vendorBillingMetaData: IVendorBillingMetaData;
  apiError: IApiErrorResponse[];
  localeID: string;
  isOptOutTOR: boolean;
  optOutReason: string;
}

export interface IVendorApprovalRequest extends ICommonRequest {
  vendorAssignmentId: string;
  vendorRateCardId: string;
  vendorAssignmentType: string;
  userRole: string;
}


export interface IVendorAppprover {
  vendorApproverID: string;
  lanId: string;
  firstName: string;
  lastName: string;
  emailId: string;
  region: string;
  country: string;
  lob: string;
}
