import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthProviderService } from 'src/app/core/services';
import { IAuthUser } from 'src/app/models';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './ch-footer.component.html',
  styleUrls: ['./ch-footer.component.css']
})
export class ChFooterComponent implements OnInit {

  constructor(
    private readonly authService: AuthProviderService
  ) {}

  authUser: IAuthUser;
  year: number = new Date().getFullYear();

  ngOnInit(): void {
    this.getAuthUser();
  }
  async getAuthUser() {
    this.authUser = await firstValueFrom(this.authService.getAuthUser());
  }
}
