import {Component, Input, OnInit} from '@angular/core';
import { AuthProviderService } from '../../../core/services';
import {IAuthUser, IVendor} from '../../../models';

@Component({
  selector: 'app-vendor-record',
  templateUrl: './vendor-record.component.html',
  styleUrls: ['./vendor-record.component.css']
})
export class VendorRecordComponent implements OnInit {
  @Input() vendorDetails: IVendor;
  user: IAuthUser;
  constructor(private readonly authServ: AuthProviderService) {}
  ngOnInit(): void {
    this.authServ.getAuthUser().subscribe(u => {
      this.user = u;
    });
  }

  setVisibility(ctrl) {
    return this.user.permissions['va-ctrl-hide-role'].findIndex(c => c === ctrl) > -1 ? false : true;
  }
}
