export const appPermissions = {
    nonclaimsuser: {
      "vm-nav-search-vendor": true,
      "vm-search-table-limit-cols": [
        "uniqueCommercialVendorReference",
        "uniqueGroupReportingVendorReference",
        "countryPanelName",
        "commercialVendorStatus",
        "commercialVendorStatusValidFrom",
        "commercialVendorStatusValidUntil",
        "commercialVendorStatusReason",
        "vendorStatus",
        "vendorStatusValidFrom",
        "vendorStatusValidUntil",
        "vendorStatusReason",
        "vendorContactName",
        "genericAssignmentRouting",
        "specificAssignmentRouting",
        "performancePriority",
        "approvalRequired",
        "rateCardEffectiveDate",
        "rateCardExpiryDate",
        "rateCardApplication",
        "t360Billing",
        "t360PanelName",
        "currencyCode",
        "rateCardPricingDescription",
        "nonTEPricing1",
        "nonTEPricing2",
        "nonTEPricing3",
        "nonTEPricing4",
        "nonTEPricing5",
        "tePricing1",
        "tePricing2",
        "tePricing3",
        "tePricing4",
        "tePricing5",
        "tePricing6",
        "tePricing7",
        "tePricing8",
        "tePricing9",
        "tePricing10",
        "definitionsandComments",
        "letterofEngagement",
        "litigationGuidelines",
        "lsa",
        "sla",
        "billingGuidelines",
        "pricingSchedule",
        
        "ResponsibleBusinessUnit",
        "NonClaimsContactManagingRel",
        "ClaimsContactManagingRel",
        "ClaimsDelegatedAuthority",
        "ClaimsDelegatedAuthLevel",
        "FinDelegatedAuthAmount",
        "DelegatedAuthHandleComplaint",
        "LossFundProvided",
        "LossFundValue",
        "AccessToClaimsSystem",
        "CGMBusinessFlag",
        "DDCompletedDate",
        "VMRiskAssessmentRating",
        "VMRiskAssessmentDate",
        "FrequencyOfAudits",
        "LastAuditDate",
        "LastAuditRating",
        "CustomerCommChannel",
        "VendorManager",
        "CreateAssignmentMode",
        "TPDDReference",
        "inScopeAssignment",
        "chubbSystemsAccessed",
        "TPCRDate",
        "TPCRRating",
        "TPCRReference"
      ],
      "vm-search-by-vendor-status": false,
      "vm-add-new-vendor": false,
      "vm-view-vendor-tcpap": false,
      "vm-view-vendor": true,
      "vm-view-vendor-pricing": false,
      "vm-view-vendor-standarddocuments": false,
      "vm-edit-vendor": false,
      "vm-expire-vendor": false,
      "vm-suspend-vendor": false,
      "vm-export-vendor-excel": false,
      "vm-search-select-vendor": false,
      "vm-search-next": false,
      "vm-bulk-upload": false,
      "vm-bulk-update-existing-database-records": false,
      "vm-approve-user-access-permissions": false,
      "va-sec-hide-meta-bg": false,
      "va-sec-hide-meta-fe": false,
      "va-sec-hide-meta-bm": false,
      "va-sec-hide-meta-doc": false,
      "va-ctrl-hide-role": [
        "isAssignmentConfidential",
        "uniqueGroupReportingVendorReference",
        "uniqueCommercialVendorReference",
        "countryPanelName",
        "vendorStatus",
        "vendorStatusvalidfrom",
        "vendorStatusvaliduntil",
        "vendorStatusReason",
        "localorTravelGeographicCoverage",
        "vendorContactName",
        "genericassignmentRouting",
        "approvalRequired",
        "rateCardEffectiveDate",
        "rateCardExpiryDate",
        "rateCardApplication",
        "t360Billing",
        "t360PanelName",
        "currencyCode",
        "rateCardPricingDescription",
        "nont&EPricing",
        "t&EPricing",
        "definitions&Comments",
        "letterofEngagement",
        "litigationGuidelines",
        "lSA",
        "sLA",
        "billingGuidelines",
        "pricingSchedule"
      ],
      "va-create-assignment": false,
      "va-upload-document": false,
      "va-edit-assignment": false,
      "va-cancel-assignment": false,
      "va-view-assignment": false,
      "va-approve-reject-assignments": false,
      "va-accept-decline-assignment": false,
      "va-view-download-attachment": false,
      "va-upload-invoice-supporting-documents": false,
      "va-search-assignment": false,
      "va-search-assignment-by-claims-handler": false,
      "va-search-assignment-by-vendor-type": false,
      "va-bulk-upload": false,
      "vt-footer-indonesian-terms": false
    },
    tpauser: {
      "vm-nav-search-vendor": true,
      "vm-search-table-limit-cols": [
        "uniqueCommercialVendorReference",
        "uniqueGroupReportingVendorReference",
        "countryPanelName",
        "commercialVendorStatus",
        "commercialVendorStatusValidFrom",
        "commercialVendorStatusValidUntil",
        "commercialVendorStatusReason",
        "vendorStatus",
        "vendorStatusValidFrom",
        "vendorStatusValidUntil",
        "vendorStatusReason",
        "vendorContactName",
        "businessDependentPolicyholderName",
        "genericAssignmentRouting",
        "approvalRequired",
        "rateCardEffectiveDate",
        "rateCardExpiryDate",
        "rateCardApplication",
        "t360Billing",
        "t360PanelName",
        "currencyCode",
        "rateCardPricingDescription",
        "nonTEPricing1",
        "nonTEPricing2",
        "nonTEPricing3",
        "nonTEPricing4",
        "nonTEPricing5",
        "tePricing1",
        "tePricing2",
        "tePricing3",
        "tePricing4",
        "tePricing5",
        "tePricing6",
        "tePricing7",
        "tePricing8",
        "tePricing9",
        "tePricing10",
        "definitionsandComments",
        "letterofEngagement",
        "litigationGuidelines",
        "lsa",
        "sla",
        "billingGuidelines",
        "pricingSchedule",
        
        "ResponsibleBusinessUnit",
        "NonClaimsContactManagingRel",
        "ClaimsContactManagingRel",
        "ClaimsDelegatedAuthority",
        "ClaimsDelegatedAuthLevel",
        "FinDelegatedAuthAmount",
        "DelegatedAuthHandleComplaint",
        "LossFundProvided",
        "LossFundValue",
        "AccessToClaimsSystem",
        "CGMBusinessFlag",
        "DDCompletedDate",
        "VMRiskAssessmentRating",
        "VMRiskAssessmentDate",
        "FrequencyOfAudits",
        "LastAuditDate",
        "LastAuditRating",
        "CustomerCommChannel",
        "VendorManager",
        "CreateAssignmentMode",
        "TPDDReference",
        "inScopeAssignment",
        "chubbSystemsAccessed",
        "TPCRDate",
        "TPCRRating",
        "TPCRReference"
      ],
      "vm-search-by-vendor-status": false,
      "vm-add-new-vendor": false,
      "vm-view-vendor-tcpap": false,
      "vm-view-vendor": true,
      "vm-view-vendor-pricing": false,
      "vm-view-vendor-standarddocuments": false,
      "vm-edit-vendor": false,
      "vm-expire-vendor": false,
      "vm-suspend-vendor": false,
      "vm-export-vendor-excel": false,
      "vm-search-select-vendor": false,
      "vm-search-next": false,
      "vm-bulk-upload": false,
      "vm-bulk-update-existing-database-records": false,
      "vm-approve-user-access-permissions": false,
      "va-sec-hide-meta-bg": false,
      "va-sec-hide-meta-fe": false,
      "va-sec-hide-meta-bm": false,
      "va-ctrl-hide-role": [
        "isAssignmentConfidential",
        "uniqueGroupReportingVendorReference",
        "uniqueCommercialVendorReference",
        "countryPanelName",
        "vendorStatus",
        "vendorStatusvalidfrom",
        "vendorStatusvaliduntil",
        "vendorStatusReason",
        "localorTravelGeographicCoverage",
        "vendorContactName",
        "genericassignmentRouting",
        "approvalRequired",
        "rateCardEffectiveDate",
        "rateCardExpiryDate",
        "rateCardApplication",
        "t360Billing",
        "t360PanelName",
        "currencyCode",
        "rateCardPricingDescription",
        "nont&EPricing",
        "t&EPricing",
        "definitions&Comments",
        "letterofEngagement",
        "litigationGuidelines",
        "lSA",
        "sLA",
        "billingGuidelines",
        "pricingSchedule",
        "mVendorContactName"
      ],
      "va-create-assignment": true,
      "va-upload-document": true,
      "va-edit-assignment": true,
      "va-cancel-assignment": true,
      "va-view-assignment": true,
      "va-approve-reject-assignments": false,
      "va-accept-decline-assignment": false,
      "va-view-download-attachment": false,
      "va-upload-invoice-supporting-documents": false,
      "va-search-assignment": true,
      "va-search-assignment-by-claims-handler": false,
      "va-search-assignment-by-vendor-type": true,
      "va-bulk-upload": false,
      "vt-footer-indonesian-terms": false
    },
    claimuser: {
      "vm-nav-search-vendor": true,
      "vm-search-table-limit-cols": [
        "uniqueCommercialVendorReference",
        "uniqueGroupReportingVendorReference",
        "countryPanelName",
        "commercialVendorStatus",
        "commercialVendorStatusValidFrom",
        "commercialVendorStatusValidUntil",
        "commercialVendorStatusReason",
        "vendorStatus",
        "vendorStatusValidFrom",
        "vendorStatusValidUntil",
        "vendorStatusReason",
        "vendorContactName",
        "genericAssignmentRouting",
        "approvalRequired",
        "rateCardEffectiveDate",
        "rateCardExpiryDate",
        "rateCardApplication",
        "t360Billing",
        "letterofEngagement",
        "litigationGuidelines",
        "lsa",
        "sla",
        "billingGuidelines",
        "pricingSchedule"
      ],
      "vm-search-by-vendor-status": false,
      "vm-add-new-vendor": false,
      "vm-view-vendor-tcpap": false,
      "vm-view-vendor": true,
      "vm-view-vendor-pricing": true,
      "vm-view-vendor-standarddocuments": false,
      "vm-edit-vendor": false,
      "vm-expire-vendor": false,
      "vm-suspend-vendor": false,
      "vm-export-vendor-excel": false,
      "vm-search-select-vendor": false,
      "vm-search-next": false,
      "vm-bulk-upload": false,
      "vm-bulk-update-existing-database-records": false,
      "vm-approve-user-access-permissions": false,
      "va-sec-hide-meta-bg": true,
      "va-sec-hide-meta-fe": true,
      "va-sec-hide-meta-bm": true,
      "va-ctrl-hide-role": [
        "uniqueGroupReportingVendorReference",
        "uniqueCommercialVendorReference",
        "countryPanelName",
        "vendorStatus",
        "vendorStatusvalidfrom",
        "vendorStatusvaliduntil",
        "vendorStatusReason",
        "localorTravelGeographicCoverage",
        "vendorContactName",
        "genericassignmentRouting",
        "approvalRequired",
        "rateCardEffectiveDate",
        "rateCardExpiryDate",
        "rateCardApplication",
        "t360Billing",
        "letterofEngagement",
        "litigationGuidelines",
        "lSA",
        "sLA",
        "billingGuidelines",
        "pricingSchedule",
        "mVendorContactName"
      ],
      "va-create-assignment": true,
      "va-upload-document": true,
      "va-edit-assignment": true,
      "va-cancel-assignment": true,
      "va-view-assignment": true,
      "va-approve-reject-assignments": true,
      "va-accept-decline-assignment": true,
      "va-view-download-attachment": true,
      "va-upload-invoice-supporting-documents": true,
      "va-search-assignment": true,
      "va-search-assignment-by-claims-handler": true,
      "va-search-assignment-by-vendor-type": true,
      "va-bulk-upload": false,
      "vt-footer-indonesian-terms": false
    },
    parsingteamuser: {
      "vm-nav-search-vendor": true,
      "vm-search-table-limit-cols": [],
      "vm-search-by-vendor-status": false,
      "vm-add-new-vendor": false,
      "vm-view-vendor-tcpap": false,
      "vm-view-vendor": true,
      "vm-view-vendor-pricing": true,
      "vm-view-vendor-standarddocuments": true,
      "vm-edit-vendor": false,
      "vm-expire-vendor": false,
      "vm-suspend-vendor": false,
      "vm-export-vendor-excel": false,
      "vm-search-select-vendor": false,
      "vm-search-next": false,
      "vm-bulk-upload": false,
      "vm-bulk-update-existing-database-records": false,
      "vm-approve-user-access-permissions": false,
      "va-sec-hide-meta-bg": true,
      "va-sec-hide-meta-fe": true,
      "va-sec-hide-meta-bm": true,
      "va-ctrl-hide-role": [
        "perilCauseofLoss",
        "dateReported",
        "broker",
        "dateofAssignmentsubmissionforSecondaryApproval",
        "nameofApprover",
        "dateofAssignmentSecondaryApproval",
        "dateofAssignmentSecondaryApprovalRejection",
        "reasonforAssignmentRejection",
        "dateofVendorDeclinature",
        "reasonforVendorDeclinature",
        "reasonforAssignmentCancellation",
        "genericassignmentRouting",
        "performancePriority",
        "approvalRequired",
        "claimsDocuments",
        
        "ResponsibleBusinessUnit",
        "NonClaimsContactManagingRel",
        "ClaimsContactManagingRel",
        "ClaimsDelegatedAuthority",
        "ClaimsDelegatedAuthLevel",
        "FinDelegatedAuthAmount",
        "DelegatedAuthHandleComplaint",
        "LossFundProvided",
        "LossFundValue",
        "AccessToClaimsSystem",
        "CGMBusinessFlag",
        "DDCompletedDate",
        "VMRiskAssessmentRating",
        "VMRiskAssessmentDate",
        "FrequencyOfAudits",
        "LastAuditDate",
        "LastAuditRating",
        "CustomerCommChannel",
        "VendorManager",
        "CreateAssignmentMode",
        "TPDDReference",
        "inScopeAssignment",
        "chubbSystemsAccessed",
        "TPCRDate",
        "TPCRRating",
        "TPCRReference"
      ],
      "va-create-assignment": false,
      "va-upload-document": false,
      "va-edit-assignment": false,
      "va-cancel-assignment": false,
      "va-view-assignment": false,
      "va-approve-reject-assignments": false,
      "va-accept-decline-assignment": false,
      "va-view-download-attachment": false,
      "va-upload-invoice-supporting-documents": false,
      "va-search-assignment": true,
      "va-search-assignment-by-claims-handler": true,
      "va-search-assignment-by-vendor-type": false,
      "va-bulk-upload": false,
      "vt-footer-indonesian-terms": false
    },
    claimsvmbasicuser: {
      "vm-nav-search-vendor": true,
      "vm-search-table-limit-cols": [],
      "vm-search-by-vendor-status": false,
      "vm-add-new-vendor": false,
      "vm-view-vendor-tcpap": false,
      "vm-view-vendor": true,
      "vm-view-vendor-pricing": true,
      "vm-view-vendor-standarddocuments": true,
      "vm-edit-vendor": false,
      "vm-expire-vendor": false,
      "vm-suspend-vendor": false,
      "vm-export-vendor-excel": true,
      "vm-search-select-vendor": false,
      "vm-search-next": false,
      "vm-bulk-upload": false,
      "vm-bulk-update-existing-database-records": false,
      "vm-approve-user-access-permissions": false,
      "va-sec-hide-meta-bg": true,
      "va-sec-hide-meta-fe": true,
      "va-sec-hide-meta-bm": true,
      "va-ctrl-hide-role": ["claimsDocuments"],
      "va-create-assignment": false,
      "va-upload-document": false,
      "va-edit-assignment": false,
      "va-cancel-assignment": false,
      "va-view-assignment": false,
      "va-approve-reject-assignments": false,
      "va-accept-decline-assignment": false,
      "va-view-download-attachment": false,
      "va-upload-invoice-supporting-documents": false,
      "va-search-assignment": true,
      "va-search-assignment-by-claims-handler": true,
      "va-search-assignment-by-vendor-type": false,
      "va-bulk-upload": false,
      "vt-footer-indonesian-terms": false
    },
    claimsvmadvanceduser: {
      "vm-nav-search-vendor": true,
      "vm-search-table-limit-cols": [],
      "vm-search-by-vendor-status": true,
      "vm-add-new-vendor": true,
      "vm-view-vendor-tcpap": true,
      "vm-view-vendor": true,
      "vm-view-vendor-pricing": true,
      "vm-view-vendor-standarddocuments": true,
      "vm-edit-vendor": true,
      "vm-expire-vendor": true,
      "vm-suspend-vendor": true,
      "vm-export-vendor-excel": true,
      "vm-search-select-vendor": true,
      "vm-search-next": true,
      "vm-bulk-upload": true,
      "vm-bulk-update-existing-database-records": true,
      "vm-approve-user-access-permissions": true,
      "va-sec-hide-meta-bg": true,
      "va-sec-hide-meta-fe": true,
      "va-sec-hide-meta-bm": true,
      "va-ctrl-hide-role": ["claimsDocuments"],
      "va-create-assignment": false,
      "va-upload-document": false,
      "va-edit-assignment": false,
      "va-cancel-assignment": false,
      "va-view-assignment": false,
      "va-approve-reject-assignments": false,
      "va-accept-decline-assignment": false,
      "va-view-download-attachment": false,
      "va-upload-invoice-supporting-documents": false,
      "va-search-assignment": true,
      "va-search-assignment-by-claims-handler": true,
      "va-search-assignment-by-vendor-type": false,
      "va-bulk-upload": true,
      "vt-footer-indonesian-terms": false
    },
    claimsvmsuperuser: {
      "vm-nav-search-vendor": true,
      "vm-search-table-limit-cols": [],
      "vm-search-by-vendor-status": true,
      "vm-add-new-vendor": true,
      "vm-view-vendor-tcpap": true,
      "vm-view-vendor": true,
      "vm-view-vendor-pricing": true,
      "vm-view-vendor-standarddocuments": true,
      "vm-edit-vendor": true,
      "vm-expire-vendor": true,
      "vm-suspend-vendor": true,
      "vm-export-vendor-excel": true,
      "vm-search-select-vendor": true,
      "vm-search-next": true,
      "vm-bulk-upload": true,
      "vm-bulk-update-existing-database-records": true,
      "vm-approve-user-access-permissions": true,
      "va-sec-hide-meta-bg": true,
      "va-sec-hide-meta-fe": true,
      "va-sec-hide-meta-bm": true,
      "va-ctrl-hide-role": ["claimsDocuments"],
      "va-create-assignment": true,
      "va-upload-document": true,
      "va-edit-assignment": true,
      "va-cancel-assignment": true,
      "va-view-assignment": true,
      "va-approve-reject-assignments": true,
      "va-accept-decline-assignment": true,
      "va-view-download-attachment": true,
      "va-upload-invoice-supporting-documents": true,
      "va-search-assignment": true,
      "va-search-assignment-by-claims-handler": true,
      "va-search-assignment-by-vendor-type": false,
      "va-bulk-upload": true,
      "vt-footer-indonesian-terms": false
    },
    external: {
      "vm-nav-search-vendor": false,
      "vm-search-table-limit-cols": [
        "uniqueCommercialVendorReference",
        "uniqueGroupReportingVendorReference",
        "countryPanelName",
        "commercialVendorStatus",
        "commercialVendorStatusValidFrom",
        "commercialVendorStatusValidUntil",
        "commercialVendorStatusReason",
        "vendorStatus",
        "vendorStatusValidFrom",
        "vendorStatusValidUntil",
        "vendorStatusReason",
        "vendorContactName",
        "genericAssignmentRouting",
        "specificAssignmentRouting",
        "performancePriority",
        "approvalRequired",
        "rateCardEffectiveDate",
        "rateCardExpiryDate",
        "rateCardApplication",
        "t360Billing",
        "t360PanelName",
        "currencyCode",
        "rateCardPricingDescription",
        "nonTEPricing1",
        "nonTEPricing2",
        "nonTEPricing3",
        "nonTEPricing4",
        "nonTEPricing5",
        "tePricing1",
        "tePricing2",
        "tePricing3",
        "tePricing4",
        "tePricing5",
        "tePricing6",
        "tePricing7",
        "tePricing8",
        "tePricing9",
        "tePricing10",
        "definitionsandComments",
        "letterofEngagement",
        "litigationGuidelines",
        "lsa",
        "sla",
        "billingGuidelines",
        "pricingSchedule",
        
        "ResponsibleBusinessUnit",
        "NonClaimsContactManagingRel",
        "ClaimsContactManagingRel",
        "ClaimsDelegatedAuthority",
        "ClaimsDelegatedAuthLevel",
        "FinDelegatedAuthAmount",
        "DelegatedAuthHandleComplaint",
        "LossFundProvided",
        "LossFundValue",
        "AccessToClaimsSystem",
        "CGMBusinessFlag",
        "DDCompletedDate",
        "VMRiskAssessmentRating",
        "VMRiskAssessmentDate",
        "FrequencyOfAudits",
        "LastAuditDate",
        "LastAuditRating",
        "CustomerCommChannel",
        "VendorManager",
        "CreateAssignmentMode",
        "TPDDReference",
        "inScopeAssignment",
        "chubbSystemsAccessed",
        "TPCRDate",
        "TPCRRating",
        "TPCRReference"
      ],
      "vm-search-by-vendor-status": false,
      "vm-add-new-vendor": false,
      "vm-view-vendor": false,
      "vm-view-vendor-tcpap": false,
      "vm-view-vendor-pricing": false,
      "vm-view-vendor-standarddocuments": false,
      "vm-edit-vendor": false,
      "vm-expire-vendor": false,
      "vm-suspend-vendor": false,
      "vm-export-vendor-excel": false,
      "vm-search-select-vendor": false,
      "vm-search-next": false,
      "vm-bulk-upload": false,
      "vm-bulk-update-existing-database-records": false,
      "vm-approve-user-access-permissions": false,
      "va-sec-hide-meta-bg": false,
      "va-sec-hide-meta-fe": true,
      "va-sec-hide-meta-bm": true,
      "va-ctrl-hide-role": ["uploadClaimAttachment", "uploadManualAttachment"],
      "va-create-assignment": false,
      "va-upload-document": false,
      "va-edit-assignment": false,
      "va-cancel-assignment": false,
      "va-view-assignment": false,
      "va-approve-reject-assignments": false,
      "va-accept-decline-assignment": false,
      "va-view-download-attachment": false,
      "va-upload-invoice-supporting-documents": false,
      "va-search-assignment": false,
      "va-search-assignment-by-claims-handler": false,
      "va-search-assignment-by-vendor-type": false,
      "va-bulk-upload": false,
      "vt-footer-indonesian-terms": true
    }
}