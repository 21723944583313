export interface IVendorDetailRes {
  domainType: string,
  organizationName: string,
  email: string,
  lastLoginDate: string,
  registrationDate: string,
  deactivationDate: string,
  isPendingApproval: boolean,
  isDeclined: boolean,
  isActive: boolean,
  declinedDate: string,
  approvedBy: string,
  approvedDate: string,
  declinedBy: string,
  reactivationDate: string
}

