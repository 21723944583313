import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'crux-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  year: Date;
  constructor() {
     // This is intentional
  }

  ngOnInit(): void {
    this.year = new Date();
  }
}
