import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CogErrorHandlerService implements ErrorHandler {
  constructor() {
    // This is intentional
  }
  handleError(error: any): void {
    try {
      // will call appinsight once it's ready
      console.error(error);
    } catch (e) {
      // for disconnected critical error
      console.error(error);
    }
  }
}
