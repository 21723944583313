import { Component, OnInit } from '@angular/core';
import { Adb2cState, AuthProvider, StorageLocation } from '@chubb-auth/types';
import { isEmpty } from 'lodash-es';
import { AppConstants } from './core/constants/app.const';
import { AuthProviderService, StorageService } from './core/services';
import { IRedirectState } from './models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  // enable spinner/header/footer/router
  isLoggedIn: boolean = false;
  authUser: any; 
  authProvider: AuthProvider;
  urlState: IRedirectState;
  langParam: string;

  constructor (
    private readonly storage: StorageService,
    private readonly authProviderService: AuthProviderService,
  ) {}

  ngOnInit(): void {
    // redirect login
    this.storage.removeItem(AppConstants.STORAGE_KEY_PREFERRED_LANGUAGE, StorageLocation.Session);
    this.storage.removeItem(AppConstants.STORAGE_KEY_SEARCH_ASSIGNMENT, StorageLocation.Session);
    this.storage.removeItem(AppConstants.STORAGE_KEY_SEARCH_VENDOR, StorageLocation.Session);
    this.storage.removeItem(AppConstants.STORAGE_KEY_VENDOR_DATABASE_SEARCH, StorageLocation.Session);
    this.storage.removeItem(AppConstants.STORAGE_KEY_VENDOR_RATECARD_ID, StorageLocation.Session);
    this.storage.removeItem(AppConstants.STATE_KEY_ADB2C_USER, StorageLocation.Session);

    let cacheLnchUrl = this.storage.getTypedItem<IRedirectState>(AppConstants.STORAGE_KEY_REDIRECT_URL, StorageLocation.Session);
    if (!cacheLnchUrl) {
      this.urlState = {
        path: window?.location?.pathname,
        queryParams: window?.location?.search,
      };
      this.storage.setItem(AppConstants.STORAGE_KEY_REDIRECT_URL, JSON.stringify(this.urlState), StorageLocation.Session);
    } else {
      // for handling vendor language change
      // from static login.html
      this.urlState = cacheLnchUrl;
      let qp = new URLSearchParams(window.location.search);
      let p = window?.location?.pathname;
      if (/\/vendor/.exec(p)) {
        let a = new URLSearchParams(this.urlState.queryParams);
        a.set('prefLang', qp.get('prefLang'));
        this.urlState.queryParams = `?${a.toString()}`;
        this.storage.setItem(AppConstants.STORAGE_KEY_REDIRECT_URL, JSON.stringify(this.urlState), StorageLocation.Session);
      }
    }
    this.isLoggedIn = false;
    this.authProvider = this.authProviderService.getAuthProvider();
    this.authProvider.refreshState().subscribe((state: Adb2cState) => {
      if (state.isLoggedIn) {
        this.authUser = {
          given_name: state?.userClaims['given_name'],
          family_name: state?.userClaims['family_name'],
          email: state?.userClaims['email']
        };
        this.storage.setItem(AppConstants.STATE_KEY_ADB2C_USER, JSON.stringify(state), StorageLocation.Session);
        this.authProviderService.setAuthUser(state).subscribe(() => this.isLoggedIn = state.isLoggedIn);
      } else {
        this.login();
      }
    });
  }

  login() {
    let path = window?.location?.pathname;
    const config = this.authProviderService.currentConfig;
    // for extra pramas from external systems
    const queryParams = {};
    if (/\/vendor/.exec(path)) {
      let qp = new URLSearchParams(window.location.search);
      let pl = qp.get('prefLang');

      if (!isEmpty(pl)) {
        if (pl.toUpperCase() === 'PT-BR') {
          this.langParam = 'pt';
        }
        else
          this.langParam = pl;
      }
      else {
        this.langParam = 'en';
      }
      queryParams['ui_locales'] = this.langParam;
    } else {
      if (config.queryParameters && (config.queryParameters.length > 0)) {
        config.queryParameters.forEach(
          (param: { key: string | number; value: any; }) => (queryParams[param.key] = param.value)
        );
      }
    }
    this.authProvider.login(queryParams);
  }
}
