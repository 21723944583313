import {IVendor} from './ivendor';
//used for Add/Edit request/response
export interface IVendorDetails extends IVendor  {
  linkNonTEPricing1 : string;
  linkNonTEPricing2 : string;
  linkNonTEPricing3 : string;
  linkNonTEPricing4 : string;
  linkNonTEPricing5 : string;
  linkTEPricing1 : string;
  linkTEPricing2 : string;
  linkTEPricing3 : string;
  linkTEPricing4 : string;
  linkTEPricing5 : string;
  linkTEPricing6 : string;
  linkTEPricing7 : string;
  linkTEPricing8 : string;
  linkTEPricing9 : string;
  linkTEPricing10 : string;
  linkDefinitionsandComments: string;
  updatedBy: string;
  lastModifiedBy: string;
  language: string;
  ResponsibleBusinessUnit: string,
  NonClaimsContactManagingRel: string,
  ClaimsContactManagingRel: string,
  ClaimsDelegatedAuthority: true,
  ClaimsDelegatedAuthLevel: string,
  FinDelegatedAuthAmount: string,
  DelegatedAuthHandleComplaint: string,
  LossFundProvided: string,
  LossFundValue: string,
  AccessToClaimsSystem: true,
  CGMBusinessFlag: string,
  DDCompletedDate: Date,
  VMRiskAssessmentRating: string,
  VMRiskAssessmentDate: string,
  TPCRDate: Date,
  TPCRRating: string,
  FrequencyOfAudits: string,
  LastAuditDate: Date,
  LastAuditRating: string,
  CustomerCommChannel: string,
  VendorManager: string,
  CreateAssignmentMode: string,
  TPDDReference: string,
  TPCRReference: string,
  inScopeAssignment: true,
  chubbSystemsAccessed: string
}
