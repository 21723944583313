import { Component, Input, OnInit } from '@angular/core';
import { AuthProviderService } from '../../../core/services';
import {IAuthUser, IVendorAcceptanceInformation} from '../../../models';
@Component({
  selector: 'app-vendor-acceptance-information',
  templateUrl: './vendor-acceptance-information.component.html',
  styleUrls: ['./vendor-acceptance-information.component.css']
})
export class VendorAcceptanceInformationComponent implements OnInit {

// assignment acceptance from parent
  @Input() vendoracceptance : IVendorAcceptanceInformation;
  // auth user
  user: IAuthUser;
  constructor(private readonly authServ: AuthProviderService) { }

  ngOnInit(): void {
    this.authServ.getAuthUser().subscribe(u => {
      this.user = u;
    });
  }

}
