import { Component, Input, OnInit } from '@angular/core';
import {IBillingGuideLines} from '../../../models';

@Component({
  selector: 'app-billing-guidelines',
  templateUrl: './billing-guidelines.component.html',
  styleUrls: ['./billing-guidelines.component.css']
})
export class BillingGuidelinesComponent implements OnInit {

//Billing guidelines details
  @Input() billing : IBillingGuideLines;
  constructor() {
    // this is intentional
  }

  ngOnInit(): void {
    // this is intentional
  }

}
