import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Injectable } from "@angular/core";

@Injectable()
export class EmptySpaceValidator {
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).trim().length === 0) {
      return { cannotContainSpace: true };
    }
    return null;
  }
}
