// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody tr:nth-child(odd) td {
    background-color: #fffacd !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFzc2lnbm1lbnQtaGlzdG9yeS5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0lBQ0ksb0NBQW9DO0FBQ3hDIiwiZmlsZSI6ImFzc2lnbm1lbnQtaGlzdG9yeS5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsidGJvZHkgdHI6bnRoLWNoaWxkKG9kZCkgdGQge1xuICAgIGJhY2tncm91bmQtY29sb3I6ICNmZmZhY2QgIWltcG9ydGFudDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/_components/assignment-history/assignment-history.component.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;AACA,4XAA4X","sourcesContent":["tbody tr:nth-child(odd) td {\n    background-color: #fffacd !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
