import { Component, Input, OnInit } from '@angular/core';
import { AuthProviderService } from '../../../core/services';
import {IAuthUser, IVendorBillingFeeEarnerInformation} from '../../../models';

@Component({
  selector: 'app-vendor-billing-fee-earner-information',
  templateUrl: './vendor-billing-fee-earner-information.component.html',
  styleUrls: ['./vendor-billing-fee-earner-information.component.css']
})
export class VendorBillingFeeEarnerInformationComponent implements OnInit {

// vendor billing fee from parent
  @Input() vendorbillingfee : IVendorBillingFeeEarnerInformation[];
  // auth user
  user: IAuthUser;
  constructor(private readonly authServ: AuthProviderService) { }

  ngOnInit(): void {
    this.authServ.getAuthUser().subscribe(u => {
      this.user = u;
    });
  }

}
