import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth",
    pathMatch: "full",
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./feature/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "welcome",
    loadChildren: () =>
      import("./feature/welcome/welcome.module").then((m) => m.WelcomeModule),
  },
  {
    path: "unauth",
    loadChildren: () =>
      import("./feature/unauth/unauth.module").then((m) => m.UnauthModule),
  },
  {
    path: "claim-manager",
    loadChildren: () =>
      import("./feature/claim-manager/claim-manager.module").then(
        (m) => m.ClaimManagerModule
      ),
  },
  {
    path: "claim-handler",
    loadChildren: () =>
      import("./feature/claim-handler/claim-handler.module").then(
        (m) => m.ClaimHandlerModule
      ),
  },
  {
    path: "claim-vendor",
    loadChildren: () =>
      import("./feature/claim-vendor/claim-vendor.module").then(
        (m) => m.ClaimVendorModule
      ),
  },
  {
    path: "user",
    loadChildren: () =>
      import("./feature/user/user.module").then(
        (m) => m.UserModule
      ),
  },
  {
    path: "activating-claim-vendor",
    loadChildren: () =>
      import("./feature/activating-claim-vendor/activating-claim-vendor.module").then(
        (m) => m.ActivatingClaimVendorModule
      ),
  },
  {
    path: "vendor",
    loadChildren: () =>
      import("./feature/vendor/vendor.module").then((m) => m.VendorModule),
  },
  {
    path: "invoice-upload",
    loadChildren: () =>
      import("./feature/invoice-upload/invoice-upload.module").then(
        (m) => m.InvoiceUploadModule
      ),
  },
  {
    path: "billing-guideline",
    loadChildren: () =>
      import("./feature/billing-guideline/billing-guideline.module").then(
        (m) => m.BillingGuidelineModule
      ),
  },
  {
    path: "**",
    redirectTo: "/404",
    pathMatch: "full",
  },
  {
    path: "404",
    loadChildren: () =>
      import("./feature/forbidden/forbidden.module").then(
        (m) => m.ForbiddenModule
      ),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), TranslateModule],
  exports: [RouterModule, TranslateModule],
})
export class AppRoutingModule {}
