export interface IAdb2cSearchVendor {
  Email: string;
  GroupName: string;
  GroupType?: string;
  GroupRole?: string;
  AndFilter: boolean;
}

export interface IAdb2cCreateVendor {
  AccountEnabled?: boolean;
  CreatedBy: string;
  CreatedByApplication: string;
  FirstName: string;
  LastName: string;
  UserName: string;
  Email: string;
  GroupName: string[];
  GroupRole: string[];
  GroupType: string[];
  Status?: IAdb2cCreateVendorRespStatus;
}

export interface IAdb2cCreateVendorRespStatus {
  Code: number;
  Message: string;
  AdditionalData: string;
}

export interface IAdb2cGetADGroupResps {
  value: [{ groups: string[] }];
}

export interface IVendorOrgMapReq {
  vendorAssignmentId: string;
  vendorRateCardId: string;
  vendorAssignmentType: string;
  emailId: string;
  commercialVendorName: string;
  localeID: string;
}

export interface IVendorOrgMapResp {
  mappingResponse: boolean
}


export interface IVendorSearchEmailResp {
  status: {
    Code: number;
    Message: any
    AdditionalData: any
  };
  value: IAdb2cDetail[];
}

export interface IAdb2cDetail {
  objectId: string;
  accountEnabled: boolean;
  city: string;
  country: string;
  displayName: string;
  givenName: string;
  jobTitle: string;
  email: string;
  postalCode: string;
  state: string;
  streetAddress: string;
  surname: string;
}
