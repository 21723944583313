export interface IVendorDenied {
  organizationName: string,
  email: string,
  lastModifiedBy: string,
  notificationSentCount: 0,
  lastLoginDate: string,
  notificationSentDate: string,
  deactivationDate: string,
  isPendingApproval: boolean,
  isActive: boolean,
  isDeclined: boolean,
  reactivationDate: string,
  approvedBy: string,
  domainType: string,
  DeclinedBy: string,
  approvedDate: string,
  declinedDate: string
}

