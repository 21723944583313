import {VendorAssignmentType} from './enums/app.enum';

export interface IVendorKeys {
  vendorId : string;
  vendorPanelId : string; 
  vendorRateCardId : string;
  vendorReportingGroupId : string;
  vendorContactId : string;
  vendorAssignmentId ?: string;
  vendorAssignmentType ?: VendorAssignmentType;
  claimNumber ?: string;
  region ?: string;
  country ?: string;
  mlob ?: string;
  localeID ?: string;
  sourceSystem ?: string;
  primaryVendorCategory ?: string;
  vendorServiceCategory ?: string;
  userRole?: string;
  sourceMlob?: string;
  vendorRegion?: string;
  vendorCountry?: string;
  rejectedVendorRateCardId?: string;
  vendorType?: string;
  SelectedLanId?: string;
}

export interface IReasonCodeReq {
  primaryVendorCategory: string;
  vendorServiceCategory: string;
  representationType: string;
}

export interface IInstructionCodeRul {
  primaryVendorCategory: string;
  vendorServiceCategory: string;
  representationType: string;
  instructionCode: string;
  sortOrder: number;
}

export interface IT360PayInstructionCodeRul {
  primaryVendorCategory: string;
  vendorServiceCategory: string;
  representationType: string;
  applicable: string;
  t360PayInstructionCode: string;
  sortOrder: number;
}

export interface IT360InstructionCodeRul {
  primaryVendorCategory: string;
  vendorServiceCategory: string;
  representationType: string;
  applicable: string;
  t360InstructionCode: string;
  sortOrder: number;
}
