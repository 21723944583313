export class AppConstants {
  // State key
  public static readonly STATE_KEY_ADB2C_USER: string =
    "418df68a-a639-4941-97f1-b31dd36f0cf0";
  public static readonly STATE_KEY_ADB2C_CONFIG: string = "CLIENT_CENTER_AUTH";

  //1659164a-6089-4052-a53f-fbeb06604bf4

  // storage keys start
  public static readonly STORAGE_KEY_REDIRECT_URL: string =
    "b754a489-101f-4c15-ac8c-6bf69b68ca49";
  public static readonly STORAGE_KEY_APP_CONFIG: string =
    "dcb968f1-e567-40ba-b7d3-f29e780d773d";
  public static readonly STORAGE_KEY_SEARCH_ASSIGNMENT: string =
    "e41e99c4-93e6-4358-a1b3-287182f86df8";
  public static readonly STORAGE_KEY_SEARCH_VENDOR: string =
    "b4dcfe4c-dcb7-43f9-8072-79a9f0984df9";
  public static readonly STORAGE_KEY_VENDOR_DATABASE_SEARCH =
    "24cc9778-f2aa-46d2-a239-40256b255683";
  public static readonly STORAGE_KEY_PREFERRED_LANGUAGE =
    "de4703d0-782c-4071-ab52-e89d8b94ebcc";
  public static readonly STORAGE_KEY_VENDOR_RATECARD_ID: string =
    "74c6cadf-0d19-486c-b967-b1350e9f9106";
  public static readonly STORAGE_KEY_SEARCH_VENDOR_ASSIGNMENT: string =
    "AAC8A24F-B3E1-4EE7-A232-3710B105C00D";
  // storage keys end

  // EXTRAS
  // vendor search/create adb2c app name
  public static readonly APPNAME_VENDOR_ADB2C: string =
    "COGVendorAssignmentApp";

  // contant values
  public static readonly DEFAULT_ID: string =
    "00000000-0000-0000-0000-000000000000";

  // claim connect source
  public static readonly CLAIM_CONNECT_SOURCE: string[] = [
    "CPC",
    "GCA",
    "GCE",
    "CPE",
  ];

  //max claim doc
  public static readonly maxclaimdocs = 10;

  //auto refresh
  public static readonly AUTO_REFRESH_INTERVAL = 30000;

  ////Timeout Error
  public static readonly TIMEOUT_INTERVAL = 180000;
}
