export interface IDependantCodeListResponse {
  source: string;
  category: string;
  dependantCategory: string;
  dependantCodeList: IDependantCodeList[];
}

export interface IDependantCodeList {
  code: string;
  value: string;
  dependands: IDependands[];
}

export interface IDependands {
  dependantCode: string;
  dependantValue: string;
}
