import {Component, Input, OnInit} from '@angular/core';
import {AuthProviderService} from '../../../core/services';
import {IAuthUser, IClaimsInformation} from '../../../models';

@Component({
  selector: 'app-claim-record',
  templateUrl: './claim-record.component.html',
  styleUrls: ['./claim-record.component.css']
})
export class ClaimRecordComponent implements OnInit {
  // auth user
  user : IAuthUser;
  // claim record from parent
  @Input() claim : IClaimsInformation;

  constructor (private readonly authServ : AuthProviderService) {}

  ngOnInit() : void {
    this.authServ.getAuthUser().subscribe(u => {
      this.user = u;
    });
  }

  setVisibility(ctrl) {
    return this.user.permissions['va-ctrl-hide-role'].findIndex(c => c === ctrl) > -1 ? false : true;
  }
}
