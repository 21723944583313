import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { isEmpty } from "lodash-es";
import {
  ApiUrls,
  AuthProviderService,
  CogErrorHandlerService,
  ConfigDataService,
} from "../../../core/services";
import {
  IAppConfig,
  IAuthUser,
  IClaimDocuments,
  IRetrieveDocument,
} from "../../../models";
import { DialogData } from "../dialog/models/dialog-data.model";
import { DialogOptions } from "../dialog/models/dialog-options.model";
import { DialogFactoryService } from "../dialog/services/dialog-factory.service";
import { DialogService } from "../dialog/services/dialog.service";

@Component({
  selector: "app-retrieve-claim-document",
  templateUrl: "./retrieve-claim-document.component.html",
  styleUrls: ["./retrieve-claim-document.component.scss"],
})
export class RetrieveClaimDocumentComponent implements OnInit, OnChanges {
  _config: IAppConfig;
  isSpin: boolean = false;
  isDisabled: boolean = false;
  isVisible: boolean = false;

  @Input() ClaimNumber: string;
  @Input() Country: string;
  @Input() Region: string;
  @Input() refresh: number;

  retDocList: IRetrieveDocument[] = [];
  selectedRetrieveFiles: IRetrieveDocument[] = [];
  authUser: IAuthUser;

  @Output() select = new EventEmitter<IRetrieveDocument[]>();
  @Output() remove = new EventEmitter<IRetrieveDocument>();

  dialog: DialogService;

  @ViewChild("retDocDialogTemplate")
  retDocDialogTemplate: TemplateRef<any>;
  @ViewChild("warningMessageDialogTemplate")
  warningMessageDialogTemplate: TemplateRef<any>;

  form: UntypedFormGroup;
  chkBxAlingmtn: DialogOptions;

  constructor(
    private readonly http: HttpClient,
    private readonly errorHandler: CogErrorHandlerService,
    private readonly config: ConfigDataService,
    private readonly dialogFactoryService: DialogFactoryService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly translate: TranslateService,
    private readonly authService: AuthProviderService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.retDocList = [];
    this.selectedRetrieveFiles = [];
  }

  ngOnInit(): void {
    // auth user
    this.authService.getAuthUser().subscribe((u) => {
      this.authUser = u;
    });
    this._config = this.config.getConfig();
  }

  // retrieve attachment - start
  openReterivalDialog() {
    if (this.ClaimNumber) {
      this.isSpin = true;
      this.isDisabled = true;
      this.getClaimDocuments().subscribe({
        next: (res) => {
          this.isSpin = false;
          this.isDisabled = false;
          if (!isEmpty(res.apiError)) {
            this.openDialog({
              headerText: "",
              message: this.translate.instant("apiResponse.errorRetClaimDocument"),
              template: this.warningMessageDialogTemplate,
              isLocalTemplate: true,
              isErrorTemplate: true,
            });
            return;
          }
          this.onRetrievalDialogs(res)
        },
        error: (err) => {
          this.isSpin = false;
          this.isDisabled = false;
          this.errorHandler.handleError(err);
          this.openDialog({
            headerText: "",
            message: this.translate.instant("apiError.documentRetrieval"),
            template: this.warningMessageDialogTemplate,
            isLocalTemplate: true,
            isErrorTemplate: true,
          });
        }
      });
    } else {
      this.openDialog({
        headerText: "",
        message: this.translate.instant(
          "warningnMessages.claimNumNotAvailable"
        ),
        template: this.warningMessageDialogTemplate,
        isLocalTemplate: true,
      });
    }
  }

  onRetrievalDialogs(res: IClaimDocuments) {
    this.retDocList = res?.claimsDocuments;
    let len = this.retDocList?.length;
    if (len > 0) {
      const controlArray = this.retDocList.map(
        (c) => new UntypedFormControl(false)
      );
      this.form = this.formBuilder.group({
        retDocs: new UntypedFormArray(controlArray),
      });

      if (len >= 1 && len <= 4) {
        this.chkBxAlingmtn = {
          height: "auto",
          width: "60",
          disableClose: true,
        };
      } else {
        this.chkBxAlingmtn = {
          height: "50",
          width: "90",
          disableClose: true,
        };
      }
      this.openDialog({
        headerText: this.translate.instant("assignment.selectFile"),
        template: this.retDocDialogTemplate,
        isLocalTemplate: true,
      });
    } else {
      this.openDialog({
        headerText: "",
        message: this.translate.instant("warningnMessages.noDocAvailable"),
        template: this.warningMessageDialogTemplate,
        isLocalTemplate: true,
      });
    }
  }

  proceed() {
    this.isSpin = true;
    this.selectedRetrieveFiles = [];
    let ca = this.form.controls["retDocs"] as UntypedFormArray;
    for (let i = 0; i < ca.controls.length; i++) {
      if (ca.controls[i].value) {
        this.selectedRetrieveFiles.push(this.retDocList[i]);
      }
    }
    this.select.emit(this.selectedRetrieveFiles);
    this.isSpin = false;
    this.cancelDialog();
  }

  deleteRetFile(index: number) {
    this.remove.emit(this.selectedRetrieveFiles[index]);
    this.selectedRetrieveFiles.splice(index, 1);
  }
  // retrieve attachment - end
  private openDialog(dialogData: DialogData): void {
    this.dialog = this.dialogFactoryService.open(
      dialogData,
      this.chkBxAlingmtn
    );
  }

  cancelDialog() {
    this.dialog.close();
  }

  private getClaimDocuments() {
    return this.http.get<IClaimDocuments>(
      `${this._config.apiUrl.baseApiUrlAssignment
      }/${ApiUrls.RetrieveClaimAttachment.replace(
        "{UniqueReferenceID1}",
        this.ClaimNumber
      )
        .replace("{UniqueReferenceID2}", this.Region.toLocaleUpperCase())
        .replace("{UniqueReferenceID3}", this.Country.toLocaleUpperCase())
        .replace("{UniqueReferenceID4}", this.authUser.lanid)}`
    );
  }
}
