// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody tr:nth-child(odd) td {
    background-color: #fff9f9 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1hbnVhbC1yZWNvcmQuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtJQUNJLG9DQUFvQztBQUN4QyIsImZpbGUiOiJtYW51YWwtcmVjb3JkLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJ0Ym9keSB0cjpudGgtY2hpbGQob2RkKSB0ZCB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogI2ZmZjlmOSAhaW1wb3J0YW50O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/_components/manual-record/manual-record.component.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;AACA,gXAAgX","sourcesContent":["tbody tr:nth-child(odd) td {\n    background-color: #fff9f9 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
