import {Component, Input, OnInit} from '@angular/core';
import {IAssignmentManualInfo} from './../../../models/iassignment-manual-info';

@Component({
  selector: 'app-panel-manual-record',
  templateUrl: './panel-manual-record.component.html',
  styleUrls: ['./panel-manual-record.component.css']
})
export class PanelManualRecordComponent implements OnInit {
  @Input() manual : IAssignmentManualInfo;
  constructor() {
     // This is intentional
  }
  ngOnInit(): void {
     // This is intentional
  }
}
