import { ICommonRequest } from "./iCommonRequest";

export interface IAssignmentSearchRequest extends ICommonRequest  {
  region: string;
  country: string;
  majorLineofBusiness: string;
  primaryVendorCategory: string;
  vendorServiceCategory: string;
  vendorType: string;
  representationType: string;
  searchRowCount: number;
}

export interface IAssignmentSearchRequestLanId {
  UserLanID: string,
  LastName: string,
  FirstName: string
}