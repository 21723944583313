export interface IVendorAcceptanceInformation {  
  confirmedLeadTimekeeper: string;
  confirmedLeadTimekeeperPhone: string;
  uniqueReferenceID: string;
}

export interface IVendorBillingFeeEarnerInformation {
  billingTimekeeperName: string;
  currencyType: string;
  position: string;
  rateByHour: number;
}

export interface IVendorBillingMetaData {
  addressLine1 : string;
  addressLine2 : string;
  city : string;
  country : string;
  email : string;
  phoneNumber : string;
  pricingSchedule : string;
  vatPercentage : string;
  vatRegistrationNumber : string;
  vatResponsibility : string;
  zipCode : string;
}
