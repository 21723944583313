export interface IAuthUser {
  isLoggedIn: boolean;
  lanid: string;
  name: string;
  email: string;
  family_name: string;
  given_name: string;
  idp?: string;
  role?: string;
  adgroups?: string[];
  organizationName: string;
  permissions?: IAuthUserPermission;
}

export interface ICogAdb2cState {
  isLoggedIn: boolean;
  userName?: string;
  idToken?: any;
  accessToken?: string;
  userClaims?: IAuthUser;
}

export interface IAuthUserPermission {
  "vm-nav-search-vendor": boolean;
  "vm-search-table-limit-cols": string[];
  "vm-search-by-vendor-status": boolean;
  "vm-add-new-vendor": boolean;
  "vm-view-vendor": boolean;
  "vm-view-vendor-pricing": boolean;
  "vm-view-vendor-standarddocuments": boolean;
  "vm-edit-vendor": boolean;
  "vm-expire-vendor": boolean;
  "vm-suspend-vendor": boolean;
  "vm-export-vendor-excel": boolean;
  "vm-search-select-vendor": boolean;
  "vm-search-next": boolean;
  "vm-bulk-upload": boolean;
  "vm-bulk-update-existing-database-records": boolean;
  "vm-approve-user-access-permissions": boolean;
  "va-sec-hide-meta-bg": boolean;
  "va-sec-hide-meta-fe": boolean;
  "va-sec-hide-meta-bm": boolean;
  "va-ctrl-hide-role": string[];
  "va-create-assignment": boolean;
  "va-upload-document": boolean;
  "va-edit-assignment": boolean;
  "va-cancel-assignment": boolean;
  "va-view-assignment": boolean;
  "va-approve-reject-assignments": boolean;
  "va-accept-decline-assignment": boolean;
  "va-view-download-attachment": boolean;
  "va-upload-invoice-supporting-documents": boolean;
  "va-search-assignment": boolean;
  "va-search-assignment-by-claims-handler": boolean;
}

export interface IADUser {
  email: string;
  family_name: string;
  given_name: string;
  name: string;
  picture: string;
  sub: string;
}

export interface IRedirectState {
  path: string;
  queryParams: string;
}
