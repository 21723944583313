import { ICommonRequest } from "./iCommonRequest";

export interface IVendorSearchRequest extends ICommonRequest {
  region: string[];
  country: string[];
  majorLineOfBusiness: string[];
  vendorPanelDesc: string[];
  vendorCategory: string[];
  vendorServiceCategory: string[];
  representationType: string[];
  VendorStatus: string[];
  groupReportingVendorName: string;
  commercialVendorName: string;
  searchRowCount: number;
  userRole: string;
}
