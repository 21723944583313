import { Component, Input } from "@angular/core";

@Component({
  selector: "app-dialog-button",
  template: `
    <button mat-raised-button [color]="color" [disabled]="disabled">
      <ng-content></ng-content>
    </button>
  `,
})
export class DialogButtonComponent {
  @Input() color = "default";
  @Input() disabled = false;
}
