import { Injectable } from '@angular/core';
import { ICreateAssignment, IVendorKeys } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class VendorStateService {
  cache: IVendorKeys;
  createAssignment: ICreateAssignment;
  constructor() {
    // This is intentional
  }
}
