import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { QuillEditorComponent, QuillModule } from "ngx-quill";
import * as DOMPurify from "dompurify";

@Component({
  selector: "app-text-editor",
  templateUrl: "./text-editor.component.html",
  styleUrls: ["./text-editor.component.scss"],
})
export class TextEditorComponent implements OnInit {
  @Input() control: FormControl;
  @Input() placeHolder: string = "";
  @Input() required: boolean = false;
  @Input() viewOnly: boolean = false;
  @Input() initialContent: string = "";

  @ViewChild(QuillEditorComponent, { static: false }) editor: QuillEditorComponent;

  public modules: QuillModule;
  public formats: string[];
  private editorId: string = 'inputVendorInstructions';

  ngOnInit(): void {
    // sanitize content
    if (!this.initialContent && this.control) {
      this.control.setValue(this.sanitizeHtml(this.control.value));
    } else if (this.initialContent && !this.control){
      this.initialContent = this.sanitizeHtml(this.initialContent);
    }

    // define allowed formats
    this.formats = [
      "bold",
      "italic",
      "underline",
      "strike",
      "font",
      "size",
      "header",
      "list",
      "bullet",
      "color",
      "background",
      "align",
    ];

    // set toolbar options
    if (!this.viewOnly) {
      this.modules = {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          [{ font: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
        ],
      };
    } else {
      this.modules = {
        toolbar: false,
      };
    }
  }

  onEditorCreated(quill: any) {
    const editorElem = quill.root;

    // Set ID on the contentEditable area, used for automation testing
    if (editorElem) {
      editorElem.id = this.editorId;
    }
  }

  // sanitize content
  sanitizeHtml(str: string): string {
    const cleanHtml = DOMPurify.sanitize(str || "", {
      ALLOWED_TAGS: [
        "strong",
        "em",
        "u",
        "strike",
        "p",
        "br",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "ul",
        "ol",
        "li",
        "span",
      ],
      ALLOWED_ATTR: ["style", "class"], // Allow 'style' attribute to keep colors
    });

    return cleanHtml as string;
  }
}
