import { IApiErrorResponse } from ".";

export interface ICompleteAssignmentRequ {
  vendorAssignmentId : string;
  secApproverLanId: string;
  vendorApproverID: string;
  claimNumber : string;
  localeId : string;
  actionField : string;
  assignmentStatus : string;
  cancellationReasonCode: string;
  updateFlag: boolean;
  lastModifiedBy: string;
}

export interface ICompleteAssignmentResp {
  vendorAssignmentId : string;
  successFlag : boolean;
  returnMessage : string;
  status: string;
  apiError: IApiErrorResponse[];
}
