import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isNil } from "lodash-es";
import { Observable, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { IAppConfig, IJwtToken } from "../../models";
import { ConfigDataService } from "../services";

@Injectable()
export class CogDocumentMgmntInterceptor implements HttpInterceptor {
  commonHeader: IJwtToken = null;
  _config: IAppConfig;

  constructor(
    private readonly http: HttpClient,
    private readonly config: ConfigDataService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!isNil(req) && req.url.indexOf("claims.cogdocumentmanagement") > -1) {
      this._config = this.config.getConfig();
      if (
        this.commonHeader != null &&
        Number(this.commonHeader.expires_on) > (Date.now() + 300000) / 1000
      ) {
        req = req
          .clone({
            setHeaders: {
              Authorization: `Bearer ${this.commonHeader.access_token}`,
            },
          })
          .clone({ setHeaders: { apiVersion: "1" } })
          .clone({
            setHeaders: {
              "Ocp-Apim-Subscription-Key":
                this._config.jwt.cogdocumentmanagement.ocpApimSubscriptionKey,
            },
          });
        return next.handle(req).pipe(
          catchError((error) => {
            return throwError(() => error);
          })
        );
      } else {
        return this.getToken().pipe(
          map((token) => {
            this.commonHeader = token;
            return req
              .clone({
                setHeaders: { Authorization: `Bearer ${token.access_token}` },
              })
              .clone({ setHeaders: { apiVersion: "1" } })
              .clone({
                setHeaders: {
                  "Ocp-Apim-Subscription-Key":
                    this._config.jwt.cogdocumentmanagement
                      .ocpApimSubscriptionKey,
                },
              });
          }),
          switchMap((clonedRequest) => {
            return next.handle(clonedRequest).pipe(
              catchError((error) => {
                return throwError(() => error);
              })
            );
          })
        );
      }
    } else {
      return next.handle(req).pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
    }
  }

  getToken() {
    const headers = new HttpHeaders({
      APP_ID: this._config.jwt.cogdocumentmanagement.apP_ID,
      APP_KEY: this._config.jwt.cogdocumentmanagement.apP_KEY,
      apiVersion: this._config.jwt.cogdocumentmanagement.apiVersion,
      Resource: this._config.jwt.cogdocumentmanagement.resource,
      "Content-type": "application/json",
      "Content-Security-Policy": "default-src 'self' 'unsafe-inline'; img-src https://* 'self' data:;object-src 'none'; base-uri 'self';style-src 'unsafe-inline';",
    });
    return this.http.post<IJwtToken>(
      this._config.jwt.cogdocumentmanagement.url,
      null,
      { headers: headers }
    );
  }
}
