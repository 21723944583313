import { VendorAssignmentType } from './enums/app.enum';
import { ICommonRequest } from './iCommonRequest';

export interface IAssignmentViewRequest extends ICommonRequest {
  vendorAssignmentID: [];
  claimNumber: string;
  region: string;
  country: string;
  assignmentCreationStartDate: string;
  assignmentCreationEndDate: string;
  role: string;
  searchBy: number;
  CreatorLanId: string;
}

export interface IVendorAssignmentViewRequest extends ICommonRequest {
  claimNumber: string;
  vendorAssignmentID: string;
  vendorFileReference: string;
  assignmentCreationStartDate: string;
  assignmentCreationEndDate: string;
  region: string;
  country: string;
  searchBy: number;
  emailId: string;
  commercialVendorName: string;
  localeID: string
}

export interface IAssignmentViewResponse {
  assignmentCreationDate: Date;
  vendorAssignmentStatus: string;
  dtOfAsgnmntSbmsnForScndryApprvl: Date;
  claimRegion: string;
  claimCountry: string;
  chubbClaimNumber: string;
  insuredName: string;
  dateOfLoss: Date;
  commercialVendorName: string;
  vendorServiceCategory: string;
  vendorPanelDescription: string;
  representationType: string;
  vendorAssignmentID: string;
  vendorRateCardID: string;
  assignmentType: VendorAssignmentType;
  assignmentNumber: string;
}
