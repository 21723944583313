import {Component, Input, OnInit} from '@angular/core';
import { IAssignmentManualInfo } from '../../../models';

@Component({
  selector: 'app-manual-record',
  templateUrl: './manual-record.component.html',
  styleUrls: ['./manual-record.component.css']
})
export class ManualRecordComponent implements OnInit {
  // data from parent
  @Input() manual : IAssignmentManualInfo;
  constructor() {
    // This is intentional
  }
  ngOnInit(): void {
    // This is intentional
  }
}
