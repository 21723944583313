import {IVendorKeys} from './ivendor-keys';

export interface IVendor extends IVendorKeys {
  uniqueCommercialVendorReference: string;
  uniqueGroupReportingVendorReference: string;
  approvalRequired: boolean;
  billingGuidelines: string;
  broadviewMinorLineofBusiness: string;
  broadviewMinorLineofBusinessCd: string;
  businessDependentPolicyholderName: string;
  CATAgreement: string;
  commercialVendorName: string;
  country: string;
  countryCD: string;
  countryPanelName: string;
  currencyCode: string;
  definitionsandComments: string;
  genericAssignmentRouting: string;
  groupReportingVendorName: string;
  letterofEngagement: string;
  litigationGuidelines: string;
  localorTravelGeographicCoverage: string;
  lsa: string;
  majorLineofBusiness: string;
  majorLineofBusinessCd: string;
  nicheArea: string;
  nonTEPricing1: string;
  nonTEPricing2: string;
  nonTEPricing3: string;
  nonTEPricing4: string;
  nonTEPricing5: string;
  panelSegmentation: string;
  performancePriority: string;
  pricingSchedule: string;
  primaryVendorCategory: string;
  primaryVendorCategoryCd: string;
  rateCardApplication: string;
  rateCardEffectiveDate: Date;
  rateCardExpiryDate: Date;
  rateCardPricingDescription: string;
  region: string;
  representationType: string;
  representationTypeCd: string;
  sla: string;
  specificAssignmentRouting: string;
  t360Billing: string;
  t360PanelName: string;
  tePricing1: string;
  tePricing10: string;
  tePricing2: string;
  tePricing3: string;
  tePricing4: string;
  tePricing5: string;
  tePricing6: string;
  tePricing7: string;
  tePricing8: string;
  tePricing9: string;
  vendorCityCoverage: string;
  vendorContactName: string;
  vendorPanelDescription: string;
  vendorStateRegionCoverage: string;
  vendorStatus: string;
  vendorStatusReason: string;
  vendorStatusValidFrom: Date;
  vendorStatusValidUntil: Date;
  vendorServiceCategory: string;
  vendorServiceCategoryCd: string;
  vendorType: string;
  commercialVendorStatus: string;
  commercialVendorStatusValidFrom: Date;
  commercialVendorStatusValidUntil: Date;
  commercialVendorStatusReason: string;
}
