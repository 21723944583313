export interface IApiResponse {
  apiError: [
    {
      Code: string;
      Detail: string;
    }
  ]
}

export interface IApiErrorResponse {
  code: string;
  detail: string;
}
