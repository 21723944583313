export interface IClaimsInformation {
  region: string;
  regionCd: string;
  country: string;
  countryCd: string;
  majorLineofBusiness: string;
  chubbInstructingOffice: string;
  nameofChubbClaimsAdjuster: string;
  chubbAdjusterTelephoneNumber: string;
  policyNumber: string;
  claimNumber: string;
  sourceSystem: string;
  sourceClaimID: string;
  nameofPolicyholderInsured: string;
  dateofLoss: Date;
  perilCauseofLoss: string;
  dateReported: Date;
  initialReserveAmountofclaim: number;
  nameofClaimantThirdParty: string;
  lossDescription: string;
  lossCountry: string;
  lossState: string;
  lossCity: string;
  broker: string;
  catCode: string;
  deductiblePolicyExcess: string;
  claimConfidential: string;
  percentageofResponsibility: string;
  policyEffectiveDate: Date;
  policyExpirationDateDate: Date;
}
