import {Component, Input, OnInit} from '@angular/core';
import { AuthProviderService } from '../../../core/services';
import {IAssignmentManualInfo, IAuthUser} from '../../../models';

@Component({
  selector: 'app-assignment-other-information',
  templateUrl: './assignment-other-information.component.html',
  styleUrls: ['./assignment-other-information.component.css']
})
export class AssignmentOtherInformationComponent implements OnInit {

  // assignment other from parent
  @Input() other: IAssignmentManualInfo;
  // auth user
  user: IAuthUser;

  constructor(
    private readonly authServ: AuthProviderService,
  ) {}

  ngOnInit(): void {
    this.authServ.getAuthUser().subscribe(u => {
      this.user = u;
    });

  }

  setVisibility(ctrl) {
    return this.user.permissions['va-ctrl-hide-role'].findIndex(c => c === ctrl) > -1 ? false : true;
  }
}
