import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Adb2cState, StorageLocation } from "@chubb-auth/types";
import { Observable, throwError } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AppConstants } from "../constants/app.const";
import { AuthProviderService, StorageService } from "../services";

@Injectable()
export class RefreshAdb2cTokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly storage: StorageService,
    private readonly authProviderService: AuthProviderService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const str = /claims.vendorassignment/g;
    if (str.exec(req?.url)) {
      let jwt = new JwtHelperService();
      let state = this.storage.getTypedItem<Adb2cState>(
        AppConstants.STATE_KEY_ADB2C_USER,
        StorageLocation.Session
      );
      let accessToken = state?.accessToken;
      if (state && accessToken && !jwt.isTokenExpired(accessToken)) {
        req = req.clone({
          setHeaders: { "x-adb2c-access-token": `Bearer ${state.accessToken}` },
        });
        return next.handle(req).pipe(
          catchError((error) => {
            return throwError(() => error);
          })
        );
      } else {
        return this.authProviderService
          .getAuthProvider()
          .refreshState()
          .pipe(
            map((states) => {
              this.storage.setItem(
                AppConstants.STATE_KEY_ADB2C_USER,
                JSON.stringify(states),
                StorageLocation.Session
              );
              return req.clone({
                setHeaders: {
                  "x-adb2c-access-token": `Bearer ${states.accessToken}`,
                },
              });
            }),
            switchMap((clonedRequest) => {
              return next.handle(clonedRequest).pipe(
                catchError((error) => {
                  return throwError(() => error);
                })
              );
            })
          );
      }
    } else {
      return next.handle(req).pipe(
        catchError((error) => {
          return throwError(() => error);
        })
      );
    }
  }
}
