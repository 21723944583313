import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../core/common/material-components.module";
import { AssignmentHistoryComponent } from "./_components/assignment-history/assignment-history.component";
import { AssignmentManualVendorComponent } from "./_components/assignment-manual-vendor/assignment-manual-vendor.component";
import { AssignmentOtherInformationComponent } from "./_components/assignment-other-information/assignment-other-information.component";
import { BillingGuidelinesComponent } from "./_components/billing-guidelines/billing-guidelines.component";
import { ClaimRecordComponent } from "./_components/claim-record/claim-record.component";
import { DialogButtonComponent } from "./_components/dialog/components/button/button.component";
import { DialogComponent } from "./_components/dialog/components/dialog/dialog.component";
import { ManualRecordComponent } from "./_components/manual-record/manual-record.component";
import { PanelManualRecordComponent } from "./_components/panel-manual-record/panel-manual-record.component";
import { RetrieveClaimDocumentComponent } from "./_components/retrieve-claim-document/retrieve-claim-document.component";
import { SavedAttachmentsComponent } from "./_components/saved-attachments/saved-attachments.component";
import { UploadAttachmentComponent } from "./_components/upload-attachment/upload-attachment.component";
import { VendorAcceptanceInformationComponent } from "./_components/vendor-acceptance-information/vendor-acceptance-information.component";
import { VendorBillingFeeEarnerInformationComponent } from "./_components/vendor-billing-fee-earner-information/vendor-billing-fee-earner-information.component";
import { VendorBillingMetadataComponent } from "./_components/vendor-billing-metadata/vendor-billing-metadata.component";
import { VendorRecordMetaComponent } from "./_components/vendor-record-meta/vendor-record-meta.component";
import { VendorRecordComponent } from "./_components/vendor-record/vendor-record.component";
import { ViewAttachmentComponent } from "./_components/view-attachment/view-attachment.component";
import { DecodeHtmlStringPipe } from "./_pipes/decode-html-string.pipe";
import { TwoDecimalPipe } from "./_pipes/two-decimal.pipe";
import { EmptySpaceValidator } from "./_validators/empty-space-validator";
import { TextEditorComponent } from './_components/text-editor/text-editor/text-editor.component';
import { QuillEditorComponent } from 'ngx-quill';

@NgModule({
  declarations: [
    DialogComponent,
    DialogButtonComponent,
    ClaimRecordComponent,
    VendorRecordComponent,
    ManualRecordComponent,
    PanelManualRecordComponent,
    AssignmentHistoryComponent,
    BillingGuidelinesComponent,
    VendorBillingMetadataComponent,
    VendorAcceptanceInformationComponent,
    AssignmentOtherInformationComponent,
    VendorBillingFeeEarnerInformationComponent,
    UploadAttachmentComponent,
    ViewAttachmentComponent,
    VendorRecordMetaComponent,
    AssignmentManualVendorComponent,
    RetrieveClaimDocumentComponent,
    SavedAttachmentsComponent,
    DecodeHtmlStringPipe,
    TwoDecimalPipe,
    TextEditorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    QuillEditorComponent
  ],
  exports: [
    CommonModule,
    MaterialModule,
    DialogComponent,
    DialogButtonComponent,
    TranslateModule,
    ClaimRecordComponent,
    VendorRecordComponent,
    ManualRecordComponent,
    FormsModule,
    ReactiveFormsModule,
    PanelManualRecordComponent,
    AssignmentHistoryComponent,
    BillingGuidelinesComponent,
    VendorBillingMetadataComponent,
    VendorAcceptanceInformationComponent,
    AssignmentOtherInformationComponent,
    VendorBillingFeeEarnerInformationComponent,
    UploadAttachmentComponent,
    ViewAttachmentComponent,
    VendorRecordMetaComponent,
    AssignmentManualVendorComponent,
    RetrieveClaimDocumentComponent,
    SavedAttachmentsComponent,
    DecodeHtmlStringPipe,
    TwoDecimalPipe,
    TextEditorComponent
  ],
  providers: [DecodeHtmlStringPipe, TwoDecimalPipe, EmptySpaceValidator],
})
export class SharedModule {}
