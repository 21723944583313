import { IApiResponse } from './iapi-response';

export interface IExpireSuspendVendorRequest {
  vendorPanelId: string;
  vendorRateCardId: string;
  suspendExpiryStartDate: string;
  suspendExpiryEndDate: string;
  vendorStatusReason: string;
  lastModifiedBy: string;
}

export interface IExpireVendorApiResponse extends IApiResponse {
  responseStatus: string;
  successFlag: boolean;
  vendorStatus: string;
}

export interface ISuspendVendorApiResponse extends IApiResponse {
  responseStatus: string;
  uniqueCommercialVendorReference: string;
  vendorStatus: string;
  successFlag: boolean;
}

export interface IAddVendorApiResponse extends IApiResponse {
  mdmResponse: string;
  responseStatus: string;
  successFlag: boolean;
}
