// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody tr:nth-child(odd) td {
    background: #fff6fc !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJpbGxpbmctZ3VpZGVsaW5lcy5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0lBQ0ksOEJBQThCO0FBQ2xDIiwiZmlsZSI6ImJpbGxpbmctZ3VpZGVsaW5lcy5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsidGJvZHkgdHI6bnRoLWNoaWxkKG9kZCkgdGQge1xuICAgIGJhY2tncm91bmQ6ICNmZmY2ZmMgIWltcG9ydGFudDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/_components/billing-guidelines/billing-guidelines.component.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;AACA,oXAAoX","sourcesContent":["tbody tr:nth-child(odd) td {\n    background: #fff6fc !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
