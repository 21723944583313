export interface IVendorServiceCategoryFilters {
  region: string;
  country: string;
  primaryVendorCategory: string;
  vendorServiceCategory: string;
  applicable: string;
  isParsingApplicable: boolean;
  isCountryApplicableForT360: string;
  isVendorServiceCategoryApplicableForT360: string;
}

export interface IVendorServiceCategoryFiltersRequest {
  region: string;
  country: string;
  primaryVendorCategory: string;
}


export interface IGetVendorTypeFilters {
  region: string;
  country: string;
  primaryVendorCategory: string;
  vendorServiceCategory: string;
  vendorType: string;
}

export interface IGetVendorTypeFiltersRequest {
  region: string;
  country: string;
  primaryVendorCategory: string;
  vendorServiceCategory: string;
  majorLineOfBusiness: string;
}
