import { AfterViewInit, Component, Inject, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { StorageLocation } from '@chubb-auth/types';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '../../core/constants/app.const';
import { BroadCastEvent, BroadcastEventService, CommonrefDataService, StorageService } from '../../core/services';
import { EventType } from '../../models/enums/app.enum';
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "crux-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  // default language selection
  selectedLanguage = 'EN';
  supportedLang: string[] = ['EN', 'ES', 'PT'];
  // auth user
  displayName: string;
  @Input() authUser: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly comnRefService: CommonrefDataService,
    private readonly storage: StorageService,
    private readonly broadcast: BroadcastEventService
  ) { }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe(async p => {
      let querySourceSystem: string = p['sourceSystem'];
      let queryPrefLang: string = p['prefLang'];
      let langSubStr: string = queryPrefLang?.substring(0, 2).toLocaleUpperCase();
      let cacheLang: string = this.storage.getItem(AppConstants.STORAGE_KEY_PREFERRED_LANGUAGE, StorageLocation.Session);

      if (!queryPrefLang && cacheLang) {
        this.selectedLanguage = cacheLang;
      } else if (querySourceSystem && (querySourceSystem === 'CPC' || querySourceSystem === 'GCA')) {
        this.selectedLanguage = await this.onCheckLanguageOne(queryPrefLang);
      } else if (querySourceSystem && querySourceSystem === 'GCE') {
        this.selectedLanguage = await this.onCheckLanguageTwo(queryPrefLang);
      } else if (langSubStr && this.supportedLang.findIndex(l => l === langSubStr) > -1) {
        if (langSubStr === 'PT') {
          this.selectedLanguage = 'PT-BR';
        } else {
          this.selectedLanguage = langSubStr;
        }
      } else {
        this.selectedLanguage = 'EN';
      }
      this.translate.setDefaultLang(this.selectedLanguage);
      this.comnRefService.loadUxCommonRefData(this.selectedLanguage);
    });
  }
  async onCheckLanguageOne(queryPrefLang: string) {
    if (queryPrefLang === '20490' || queryPrefLang === '1034') {
      return 'ES';
    } else if (queryPrefLang === '2070') {
      return 'PT-BR';
    } else {
      return 'EN';
    }
  }
  async onCheckLanguageTwo(queryPrefLang: string) {
    if (queryPrefLang === '1034') {
      return 'ES';
    } else if (queryPrefLang === '2070') {
      return 'PT-BR';
    } else {
      return 'EN';
    }
  }

  ngOnInit(): void {
    if (this.authUser?.given_name || this.authUser?.family_name) {
      let firstName = this.authUser?.given_name ? this.authUser.given_name : '';
      let lastName = this.authUser?.family_name ? this.authUser.family_name : '';
      this.displayName = firstName + ' ' + lastName;
    } else {
      this.displayName = this.authUser?.email;
    }
    if (typeof window !== 'undefined' && window.document) {
      this.document.body.setAttribute(
        'data-bs-theme',
        'light'
      );
    }
  }

  changeLanguage(language) {
    switch (language) {
      case 'EN': {
        this.translate.use('EN');
        break;
      }
      case 'ES': {
        this.translate.use('ES');
        break;
      }
      case 'PT-BR': {
        this.translate.use('PT-BR');
        break;
      }
    }
    this.comnRefService.loadUxCommonRefData(language);
    this.broadcast.dispatch(new BroadCastEvent(EventType.LanguageChange, language));
  }
}
