export const groupKeys = [
    {
      "key": "1-claims-vm-super-user",
      "value": "908b7cd9-e793-40a5-9aeb-d925e8db9511"
    },
    {
      "key": "2-claims-vm-advanced-user",
      "value": "4ae88cb3-bcdc-4611-b9b4-0ce2bba60919"
    },
    {
      "key": "3-claims-vm-basic-user",
      "value": "c5210926-c307-4a54-9e94-69bd03e94753"
    },
    {
      "key": "4-claim-user",
      "value": "59c346fd-c03f-40b4-84d4-78cf7de73d5b"
    },
    {
      "key": "5-tpa-user",
      "value": "b24f877f-f835-4da2-956f-5451a1293d0e"
    },
    {
      "key": "6-parsing-team-user",
      "value": "13eeb63c-5e39-4be5-b6ed-1dfd97150fcf"
    },
    {
      "key": "7-non-claims-user",
      "value": "9789af72-4038-4c94-9277-c20431f68819"
    }
  ]
  