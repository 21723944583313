// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody tr:nth-child(odd) td {
  background-color: #fcf4ff !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInZlbmRvci1yZWNvcmQtbWV0YS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNJLG9DQUFBO0FBQUoiLCJmaWxlIjoidmVuZG9yLXJlY29yZC1tZXRhLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLy92ZW5kb3ItcmVjb3JkLW1ldGEuY29tcG9uZW50LnNjc3NcbnRib2R5IHRyOm50aC1jaGlsZChvZGQpIHRkIHtcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAjZmNmNGZmICFpbXBvcnRhbnRcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/shared/_components/vendor-record-meta/vendor-record-meta.component.scss"],"names":[],"mappings":"AACA;EACI,oCAAA;AAAJ;AACA,4aAA4a","sourcesContent":["//vendor-record-meta.component.scss\ntbody tr:nth-child(odd) td {\n    background-color: #fcf4ff !important\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
