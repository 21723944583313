import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";

@Component({
  selector: "app-upload-attachment",
  templateUrl: "./upload-attachment.component.html",
  styleUrls: ["./upload-attachment.component.scss"],
})
export class UploadAttachmentComponent implements OnInit, OnChanges {
  fileSize: number = 0;
  selectedUploadFiles: File[] = [];

  @Input() refresh: number;
  @Input() btnText: string;

  @Output() select = new EventEmitter<File[]>();
  @Output() remove = new EventEmitter<File[]>();

  constructor(
    private readonly translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.fileSize = 0;
    this.selectedUploadFiles = [];
  }

  ngOnInit(): void {
   // this is intentional
  }

  // upload document - start
  handleFileInput(e) {
    const file: File = e.target.files[0];
    e.target.value = null;
    if (file) {
      this.fileSize = this.fileSize + file.size;
      if (this.fileSize <= 52428800) {
        this.selectedUploadFiles.push(file);
        this.select.emit(this.selectedUploadFiles);
      } else {
        Swal.fire({
          icon: 'info',
          text: "File Size exists maximum limit of 50MB",
          confirmButtonText: this.translate.instant('common.done'),
        })
      }
    }
  }

  handleMultiFileInput(e) {
    let files: File[] = e.target.files;
    for (let value of files) {
      let file = value;
      if (file) {
        this.fileSize = this.fileSize + file.size;
      }
    }
    if (this.fileSize <= 52428800) {
      for (let values of files) {
        let file = values;
        if (file) {
          this.selectedUploadFiles.push(file);
        }
      }
      e.target.value = null;
      this.select.emit(this.selectedUploadFiles);
    } else {
      Swal.fire({
        icon: 'info',
        text: this.translate.instant("warningnMessages.allowableFileSize"),
        confirmButtonText: this.translate.instant('common.done'),
      })
    }
  }

  deleteUploadedFile(index: number) {
    this.fileSize = this.fileSize - this.selectedUploadFiles[index].size;
    this.selectedUploadFiles.splice(index, 1);
    this.remove.emit(this.selectedUploadFiles);
  }
}