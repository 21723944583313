import { ResponseMode, StorageLocation } from '@chubb-auth/types';

export interface IAppConfig {
  apiUrl: IAppBaseApiUrl;
  jwt: {
    common: IJwtTokenReqObj;
    cogcommon: IJwtTokenReqObj;
    cogvendormanager: IJwtTokenReqObj;
    cogvendorassignment: IJwtTokenReqObj;
    cogdocumentmanagement: IJwtTokenReqObj;
    cogadb2cvendor: IJwtTokenReqObj;
    cogadb2adgroup: IJwtTokenReqObj;
  };
  authProviderConfig: IAuthProviderConfig;
  appConstants: IAppConstantsConfig;
}

export interface IAppConstantsConfig {
  parsingTeamEmail: string;
}

export interface IAppBaseApiUrl {
  baseApiUrl: string;
  baseApiUrlCommonRef: string;
  baseApiUrlAppCommonRef: string;
  baseApiUrlAssignment: string;
  baseApiUrlDocMgnt: string;
  baseApiUrlVendorAdb2c: string;
  newVendorRegistrationURL: string;
}

export interface IJwtTokenReqObj {
  url: string;
  apP_ID: string;
  apP_KEY: string;
  resource: string;
  ocpApimSubscriptionKey: string;
  apiVersion: string;
}

export interface IAuthProviderConfig {
  authProvider: string;
  tenant: string;
  policy: string;
  redirectUrl: string;
  clientId: string;
  scopes: {
    scope: string;
  }[];
  queryParameters: {
    key: string;
    value: string;
  }[];
  stateLocation?: StorageLocation;
  enableLogging?: boolean;
  responseMode?: ResponseMode,
}

