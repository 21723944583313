import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'decodeHtmlString'
})
export class DecodeHtmlStringPipe implements PipeTransform {
  transform(value: string): string {
    return new DOMParser().parseFromString(value, 'text/html').documentElement.textContent;
  }
}
