import {IAssignment} from "./iassignment";

export interface ICreateAssignment extends IAssignment {
  vendorAssignmentId: string;
  vendorRateCardId: string;
  region: string;
  country: string;
  claimNumber: string;
  sourceSystem: string;
  userRole: string;
  emailId: string;
  creatorFirstName: string;
  creatorLastName: string;
  lanId: string;
  localeID: string;
  isTOROptOut: boolean;
  torOptOutReason: string;
}

export interface IAvailableVendorInformation {
  availableVendorInformation: string;
}
