import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IAppConfig} from '../../models';
import {ApiUrls} from './api-urls';
import {ConfigDataService} from './config-data.service';
import {CogErrorHandlerService} from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly _config : IAppConfig;
  constructor (
    private readonly http : HttpClient,
    private readonly error : CogErrorHandlerService,
    private readonly config : ConfigDataService,
  ) {
    this._config = this.config.getConfig();
  }

  submitAssignment(reqObj) {
    try {
      return this.http.post(`${this._config.apiUrl.baseApiUrlAssignment}/${ApiUrls.SendNotification}`, reqObj);
    } catch(e) {
      this.error.handleError(e);
    }
  }

  //notification call for denied vendor approval
  deniedNotification(reqObj) {
    this.submitAssignment(reqObj);
  }
}
