import { HttpClient } from "@angular/common/http";
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ApiUrls, AuthProviderService, ConfigDataService} from '../../../core/services';
import {DocUploadStatus, IAppConfig, IAuthUser, IReviewAttachment} from '../../../models';
import {AttachmentType} from '../../../models/enums/app.enum';
import { saveAs } from "file-saver";
import { TranslateService } from '@ngx-translate/core';

import Swal from "sweetalert2";

@Component({
  selector: 'app-saved-attachments',
  templateUrl: './saved-attachments.component.html',
})
export class SavedAttachmentsComponent implements OnInit, OnChanges {

  claimDocuments : IReviewAttachment[];
  invoices : IReviewAttachment[];
  vendorDocs : IReviewAttachment[];
  torDocs : IReviewAttachment[];

  @Input() reviewAttachments : IReviewAttachment[];
  @Output() remove = new EventEmitter<string>();

  user : IAuthUser;
  url: string = "";
  _config: IAppConfig;

  constructor (
    private readonly http: HttpClient,
    private readonly config: ConfigDataService,
    private readonly authServ: AuthProviderService,
    private translate: TranslateService
  ) {
    this._config = this.config.getConfig();
    this.url = `${this._config.apiUrl.baseApiUrlDocMgnt}/${ApiUrls.DownloadDoc}?fileNames=`;
  }

  ngOnChanges(changes : SimpleChanges) : void {
    // attachments
    let len = this.reviewAttachments?.length;
    if(len > 0) {
      this.invoices = []; this.claimDocuments = []; this.vendorDocs = []; this.torDocs = [];
      this.extractDoc(this.reviewAttachments);
    } else {
      this.invoices = []; this.claimDocuments = []; this.vendorDocs = []; this.torDocs = [];
    }
  }

  ngOnInit() : void {
    this.authServ.getAuthUser().subscribe(u => {
      this.user = u;
    });
  }

  deleteUploadedFile(documentID : string) {
    this.remove.emit(documentID);
  }

  private extractDoc(e: Array<IReviewAttachment>) {
    for (let i in e) {
      const element = e[i];
      let docType = element.documentType.toLocaleUpperCase();
      if (docType === AttachmentType.Claim) {
        this.claimDocuments.push(element);
      } else if (docType === AttachmentType.Invoice) {
        this.invoices.push(element);
      } else if (docType === AttachmentType.Vendor) {
        this.vendorDocs.push(element);
      } else if (docType === AttachmentType.TOR) {
        this.torDocs.push(element);
      }
      if (element.docStatus === "Success" || element.docStatus === "Successfull" || element.docStatus === "File Uploaded successfully" ) {
        element.docStatus = DocUploadStatus.Uploaded;
      }
    }
  }

  
  // download/view file
  viewFile(f: IReviewAttachment) {
    if (f.documentPath) {
      if ( (f.docStatus === DocUploadStatus.Uploaded && f.documentType == AttachmentType.Claim) || f.documentType != AttachmentType.Claim ) {
        this.http.get( `${this._config.apiUrl.baseApiUrlDocMgnt}/${ApiUrls.DownloadDoc}?fileNames=${encodeURIComponent(f.documentName)}&blobContainer=${f.documentPath}`, { responseType: "blob" }).subscribe((result) => {
          let blob = new Blob([result]);
          saveAs(blob, f.docDisplayName);
        });
      } else if (f.docStatus === DocUploadStatus.Pending) {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('common.warning'),
          text: this.translate.instant('warningnMessages.claimAttachmentWaitDownload'),
          confirmButtonText: this.translate.instant('common.done'),
        })
      } else if (f.docStatus === DocUploadStatus.Failed) {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('common.warning'),
          text: this.translate.instant('warningnMessages.claimAttchUploadFailed'),
          confirmButtonText: this.translate.instant('common.done'),
        })
      } else {
        return;
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('common.warning'),
        text: this.translate.instant('warningnMessages.attachmentWaitDownload'),
        confirmButtonText: this.translate.instant('common.done'),
      })
    }
  }

  viewTORFile(f: IReviewAttachment) {
    if (f.documentPath) {
      if ( (f.docStatus === DocUploadStatus.Uploaded && f.documentType == AttachmentType.Claim) || f.documentType != AttachmentType.Claim ) {
        this.http.get(`${this._config.apiUrl.baseApiUrlDocMgnt}/${ApiUrls.DownloadDoc}?fileNames=${encodeURIComponent(f.documentName.substring((f.documentName.indexOf('_')+1), (f.documentName.length)))}&blobContainer=${f.documentPath}`, { responseType: "blob" } ).subscribe((result) => {
          let blob = new Blob([result]);
          saveAs(blob, f.docDisplayName);
        });
      } else if (f.docStatus === DocUploadStatus.Pending) {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('common.warning'),
          text: this.translate.instant('warningnMessages.claimAttachmentWaitDownload'),
          confirmButtonText: this.translate.instant('common.done'),
        })
      } else if (f.docStatus === DocUploadStatus.Failed) {
        Swal.fire({
          icon: 'warning',
          title: this.translate.instant('common.warning'),
          text: this.translate.instant('warningnMessages.claimAttchUploadFailed'),
          confirmButtonText: this.translate.instant('common.done'),
        })
      } else {
        return;
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: this.translate.instant('common.warning'),
        text: this.translate.instant('warningnMessages.attachmentWaitDownload'),
        confirmButtonText: this.translate.instant('common.done'),
      })
    }
  }

  setVisibility(ctrl) {
    return this.user.permissions['va-ctrl-hide-role'].findIndex(c => c === ctrl) > -1 ? false : true;
  }
}