// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-paragraph {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 10px 0;
  text-align: left;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRpYWxvZy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7RUFDQSxnQkFBQTtFQUNBLGtCQUFBO0VBQ0EsZ0JBQUE7QUFDRiIsImZpbGUiOiJkaWFsb2cuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZGlhbG9nLXBhcmFncmFwaCB7XG4gIGZvbnQtc2l6ZTogMThweDtcbiAgZm9udC13ZWlnaHQ6IDYwMDtcbiAgbWFyZ2luOiAwIDAgMTBweCAwO1xuICB0ZXh0LWFsaWduOiBsZWZ0O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/shared/_components/dialog/components/dialog/dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACA,obAAob","sourcesContent":[".dialog-paragraph {\n  font-size: 18px;\n  font-weight: 600;\n  margin: 0 0 10px 0;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
