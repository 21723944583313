export interface IBillingGuideLines {
  chubbAdjusterTelephoneNumber: string;
  auditInstructions: string;
  NoticeIssueDate: string;
  preNotificationDate: string;
  feeAgreementDetails: string;
  reimbursementPayeeType: string;
  nameOfPersonT360: string;
  payee: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  rejectDateOfService: string;
}

export interface IBillingGuideLinesRequest {
  vendorAssignmentID: string;
  vendorAssignmentBillingGuidelinesID: string;
  lastModifiedBy: string;
  billingAuditingInstructions: IBillingAuditingInstructions;
  reimbursement: IReimbursement;
}

export interface ISaveBillingGuideLinesResponse {
  vendorAssignmentID: string;
  vendorAssignmentBillingGuidelinesID: string;
  successStatus: boolean;
  message: string;
}

export interface IGetBillingGuideLinesResponse {
  vendorAssignmentID: string;
  vendorAssignmentBillingGuidelinesID: string;
  billingAuditingInstructions: {
    auditInstructions: string;
    rejectServiceInstruction: string;
    preNotificationDate: string;
    feeAgreementDetails: string;
  };
  reimbursement: {
    reimbursementPayeeType: string;
    invoiceReceivingPersonName: string;
    payee: string;
    addressLine1: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  };
}

export interface IBillingAuditingInstructions {
  auditInstructions: string;
  rejectServiceInstruction: string;
  preNotificationDate: string;
  feeAgreementDetails: string;
}

export interface IReimbursement {
  reimbursementPayeeType: string;
  invoiceReceivingPersonName: string;
  payee: string;
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}
