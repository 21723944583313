import { AfterViewInit, Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CommonModule, DOCUMENT, LocationStrategy } from '@angular/common';
import Colours from 'src/app/config/primary-colors.json';
import { StorageLocation } from '@chubb-auth/types';
import { TranslateService } from '@ngx-translate/core';
import { BroadCastEvent, BroadcastEventService, CommonrefDataService, StorageService } from 'src/app/core/services';
import { AppConstants } from 'src/app/core/constants/app.const';
import { EventType } from 'src/app/models/enums/app.enum';

interface AuthUser {
  given_name?: string;
  family_name?: string;
  email?: string;
}

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './ch-header.component.html',
  styleUrls: ['./ch-header.component.css'],
})
export class ChHeaderComponent implements OnInit, AfterViewInit {
  // auth user
  displayName: string;
  @Input() authUser: AuthUser;

  // language
  selectedLanguage: string = 'EN';
  supportedLang: string[] = ['EN', 'ES', 'PT'];
  // scolling
  scrolled: boolean = false;
  // colors
  colors: {name: string, color: string}[] = Colours;
  selectedColor: string = '#100092';
  // homepage
  homePage: string = "/welcome";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly translate: TranslateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly comnRefService: CommonrefDataService,
    private readonly storage: StorageService,
    private readonly broadcast: BroadcastEventService,
    private readonly url: LocationStrategy
  ){}

  ngOnInit() {
    if (typeof window !== 'undefined' && window.document) {
      let savedTheme = localStorage.getItem('cogvattheme');
      if (savedTheme) {
        this.isLightTheme = JSON.parse(savedTheme);
      }
      this.document.body.setAttribute(
        'data-bs-theme',
        this.isLightTheme ? 'light' : 'dark'
      );
      let savedColor = localStorage.getItem('cogvatprimarycolor');
      if (savedColor) {
        let value = JSON.parse(savedColor);
        this.onChangeColor(value);
      }
    }
    this.defineUserName();
  }

  defineUserName() {
    if (this.authUser?.given_name || this.authUser?.family_name) {
      let firstName = this.authUser?.given_name ? this.authUser.given_name : '';
      let lastName = this.authUser?.family_name ? this.authUser.family_name : '';
      this.displayName = firstName + ' ' + lastName;
    } else {
      this.displayName = this.authUser?.email;
    }
    setTimeout(() => {
      if (this.url.path().includes('/vendor')) {
        this.homePage = '/vendor';
      }
    }, 3500);
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe(async p => {
      let querySourceSystem: string = p['sourceSystem'];
      let queryPrefLang: string = p['prefLang'];
      let langSubStr: string = queryPrefLang?.substring(0, 2).toLocaleUpperCase();
      let cacheLang: string = this.storage.getItem(AppConstants.STORAGE_KEY_PREFERRED_LANGUAGE, StorageLocation.Session);

      if (!queryPrefLang && cacheLang) {
        this.selectedLanguage = cacheLang;
      } else if (querySourceSystem && (querySourceSystem === 'CPC' || querySourceSystem === 'GCA')) {
        this.selectedLanguage = await this.onCheckLanguageOne(queryPrefLang);
      } else if (querySourceSystem && querySourceSystem === 'GCE') {
        this.selectedLanguage = await this.onCheckLanguageTwo(queryPrefLang);
      } else if (langSubStr && this.supportedLang.findIndex(l => l === langSubStr) > -1) {
        if (langSubStr === 'PT') {
          this.selectedLanguage = 'PT-BR';
        } else {
          this.selectedLanguage = langSubStr;
        }
      } else {
        this.selectedLanguage = 'EN';
      }
      this.translate.setDefaultLang(this.selectedLanguage);
      this.comnRefService.loadUxCommonRefData(this.selectedLanguage);
    });
  }
   onCheckLanguageOne(queryPrefLang: string) {
    if (queryPrefLang === '20490' || queryPrefLang === '1034') {
      return 'ES';
    } else if (queryPrefLang === '2070') {
      return 'PT-BR';
    } else {
      return 'EN';
    }
  }
  onCheckLanguageTwo(queryPrefLang: string) {
    if (queryPrefLang === '1034') {
      return 'ES';
    } else if (queryPrefLang === '2070') {
      return 'PT-BR';
    } else {
      return 'EN';
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    let header = document.getElementById('chubbHeader');
    if (document.body.scrollTop > 20 ||     
    document.documentElement.scrollTop > 20) {
      header?.classList.add('scrolled');
    } else {
      header?.classList.remove('scrolled');
    }
  }

  changeLanguage(language: string) {
    this.selectedLanguage = language;
    let langCode: string;
    switch (language) {
      case 'EN': {
        langCode = 'EN';
        break;
      }
      case 'ES': {
        langCode = 'ES';
        break;
      }
      case 'PT-BR': {
        langCode = 'PT-BR';
        break;
      }
    }
    this.translate.use(langCode);
    this.comnRefService.loadUxCommonRefData(language);
    this.broadcast.dispatch(new BroadCastEvent(EventType.LanguageChange, language));
    
  }

  public isLightTheme = true;
  onSwitchTheme() {
    this.isLightTheme = !this.isLightTheme;
    localStorage.setItem('cogvattheme', JSON.stringify(this.isLightTheme));
    this.document.body.setAttribute(
      'data-bs-theme',
      this.isLightTheme ? 'light' : 'dark'
    );
  }
  onChangeColor(color: string) {
    this.selectedColor = color;
    localStorage.setItem('cogvatprimarycolor', JSON.stringify(this.selectedColor));
    this.document.body.setAttribute(
      'data-primary-color',
      color
    )
  }

}
