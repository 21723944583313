import {Injectable} from '@angular/core';
import {StorageLocation} from '@chubb-auth/types';
import { environmentUrls } from 'src/environments/environment.urls';
import { environmentLocal } from 'src/environments/environment.local';
import { environmentSit } from 'src/environments/environment.sit';
import { environmentUat } from 'src/environments/environment.uat';
import { environmentProd } from 'src/environments/environment.prod';
import { environmentPreProd } from 'src/environments/environment.preprod';
import {IAppConfig} from '../../models';
import {AppConstants} from '../constants/app.const';
import {CogErrorHandlerService} from './error-handler.service';
import {StorageService} from './storage.service';

export function initConfigLoad(config : ConfigDataService) {
  return () => config.loadConfig();
}

@Injectable({
  providedIn: 'root'
})
export class ConfigDataService {

  constructor (
    private readonly errorHandler : CogErrorHandlerService,
    private readonly storage : StorageService
  ) {}

  loadConfig() : IAppConfig {
    try {
      const urls = environmentUrls.urls;
      const hostname = window?.location?.hostname;
      if(new RegExp(urls.local).test(hostname)) {
        // local environment config json
        this.storage.setItem(AppConstants.STORAGE_KEY_APP_CONFIG, JSON.stringify(environmentLocal), StorageLocation.Session);
        return environmentLocal;
      } else if (new RegExp(urls.sit).test(hostname)) {
        // sit environment config json
        this.storage.setItem(AppConstants.STORAGE_KEY_APP_CONFIG, JSON.stringify(environmentSit), StorageLocation.Session);
        return environmentSit;
      } else if(new RegExp(urls.uat).test(hostname)) {
        // uat environment config json
        this.storage.setItem(AppConstants.STORAGE_KEY_APP_CONFIG, JSON.stringify(environmentUat), StorageLocation.Session);
        return environmentUat;
      } else if(new RegExp(urls.perf).test(hostname)) {
        // pre-prod environment config json
        this.storage.setItem(AppConstants.STORAGE_KEY_APP_CONFIG, JSON.stringify(environmentPreProd), StorageLocation.Session);
        return environmentPreProd;
      } else if(new RegExp(urls.prod).test(hostname)) {
        // PROD environment config json
        this.storage.setItem(AppConstants.STORAGE_KEY_APP_CONFIG, JSON.stringify(environmentProd), StorageLocation.Session);
        return environmentProd;
      } else {
        console.warn(`Cannot find environment for host name ${hostname}`);
      }
    } catch(e) {
      this.errorHandler.handleError(e);
    }
  }

  getConfig() : IAppConfig {
    return this.storage.getTypedItem<IAppConfig>(AppConstants.STORAGE_KEY_APP_CONFIG, StorageLocation.Session);
  }
}
