export interface IAssignmentHistory {
  assignmentCreation: string;
  assignmentDeclineComments: string;
  assignmentNumber: string;
  assignmentStatus: string;
  secondaryApprovalSubmission: string;
  approver: string;
  secondaryApproval: string;
  secondaryApprovalRejection: string;
  assignmentRejectionComments: string;
  assignmentRejection: string;
  submissionToVendor: string;
  vendorAcceptance: string;
  vendorDeclinature: string;
  vendorDeclinatureReason: string;
  assignmentCancellation: string;
  assignmentCancellationReason: string;
  approverEmailId: string;
  vendorAssignmentCreatorEmailID: string;
  claimHandlerName: string;
  previousVendorRateCardID: string;
}
