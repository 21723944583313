export interface IVendorBillingAddress {
  address1: string;
  address2: string;
  city: string;
  country: string;
  zip: string;
  emailAddress: string;
  phoneNumber: string;
}

export interface VendorTaxInformation {
  vatPercentage: string;
  vatResponsibility: string;
  vatRegistrationNumber: string;
}

export interface IVendorBillingInformation {
  id?: number;
  billingTimeKeepersName: string;
  position: string;
  currencyType: string;
  ratePerHour: number;
  billingInformationId: string;
}

export interface IVendorInvoice {
  vendorAssignmentID: string;
  rateCardId: string;
  invoiceId: string;
  t360Billing: boolean;
  vendorBillingAddress: IVendorBillingAddress;
  vendorTaxInformation: VendorTaxInformation;
  vendorPricingSchedule: string;
  vendorBillingInformation: IVendorBillingInformation[];
  isActive: boolean;
  lastModifiedBy: string;
}

export interface IVendorInvoiceSaveResponse {
  vendorAssignmentID: string;
  invoiceId: string;
  successStatus: boolean;
  message: string;
}
