export interface IActivateUserReq {
  localeID: string;
  email: string,
  isSendNotification: boolean,
  lastModifiedBy: string,
  approvedBy: string,
  approvedDate: string,
  organizationName: string
}

