import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAdb2cCreateVendor, IAdb2cCreateVendorRespStatus, IAdb2cSearchVendor, IAppConfig, IVendorSearchEmailResp } from '../../models';
import { ApiUrls } from './api-urls';
import { ConfigDataService } from './config-data.service';
import { CogErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class VendorAdb2cService {
  private readonly _config: IAppConfig;
  constructor (
    private readonly http: HttpClient,
    private readonly errorHandler: CogErrorHandlerService,
    private readonly config: ConfigDataService,
  ) {
    this._config = this.config.getConfig();
  }

  searchVendor(reqObj: IAdb2cSearchVendor) {
    try {
      return this.http.post<IAdb2cCreateVendor[]>(`${this._config.apiUrl.baseApiUrlVendorAdb2c}/${ApiUrls.Adb2cSearchVendor}`, reqObj);
    } catch (e) {
      this.errorHandler.handleError(e);
    }
  }

  createVendor(reqObj: IAdb2cCreateVendor) {
    try {
      return this.http.post<IAdb2cCreateVendorRespStatus>(`${this._config.apiUrl.baseApiUrlVendorAdb2c}/${ApiUrls.Adb2cCreateVendor}`, reqObj);
    } catch (e) {
      this.errorHandler.handleError(e);
    }
  }

  public searchEmail(email: string) {
    let h = new HttpHeaders().set(
      'Ocp-Apim-Subscription-Key',
      this._config.jwt.cogadb2cvendor.ocpApimSubscriptionKey
    );
    return this.http.get<IVendorSearchEmailResp>(
      `${this._config.apiUrl.baseApiUrlVendorAdb2c}/${ApiUrls.SearchByEmailVendor.replace('{**}', email)}`, { headers: h });
  }
}
