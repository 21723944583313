import {Component, Input, OnInit} from '@angular/core';
import { AuthProviderService } from '../../../core/services';
import {IAuthUser, IVendor} from '../../../models';

@Component({
  selector: 'app-vendor-record-meta',
  templateUrl: './vendor-record-meta.component.html',
  styleUrls: ['./vendor-record-meta.component.scss']
})
export class VendorRecordMetaComponent implements OnInit {
  @Input() vendorDetails: IVendor;
  // auth user
  user: IAuthUser;
  constructor(private readonly authServ: AuthProviderService) {}
  ngOnInit(): void {
    this.authServ.getAuthUser().subscribe(u => {
      this.user = u;
    });
  }
  setVisibility(ctrl) {
    return this.user.permissions['va-ctrl-hide-role'].findIndex(c => c === ctrl) > -1 ? false : true;
  }
}
