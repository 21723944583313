import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {StorageLocation} from '@chubb-auth/types';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {
  IAppConfig,
  ICodeList,
  ICrossWalksResp,
  IDependantCodeListResponse,
  IGroupAndCommercialVendorSearchRequest,
  IVendorCountriesResponse,
  IVendorNamesResponse
} from '../../models';
import {AppConstants} from '../constants/app.const';
import {ApiUrls} from './api-urls';
import {ConfigDataService} from './config-data.service';
import {CogErrorHandlerService} from './error-handler.service';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommonrefDataService {
  private _config: IAppConfig;
  private _commUxCodeDecodeValues: BehaviorSubject<ICodeList[]> = new BehaviorSubject<ICodeList[]>(null);

  uxCommonRefData$ = this._commUxCodeDecodeValues.asObservable();

  private _depRegCountryCodeList: BehaviorSubject<IDependantCodeListResponse> = new BehaviorSubject<IDependantCodeListResponse>(null);
  private _depMlobBlobCodeList: BehaviorSubject<IDependantCodeListResponse> = new BehaviorSubject<IDependantCodeListResponse>(null);
  private _depPvcVscCodeList: BehaviorSubject<IDependantCodeListResponse> = new BehaviorSubject<IDependantCodeListResponse>(null);
  private _depCountCountPanCodeList: BehaviorSubject<IDependantCodeListResponse> = new BehaviorSubject<IDependantCodeListResponse>(null);
  private _vendorNames: BehaviorSubject<IVendorNamesResponse[]> = new BehaviorSubject<IVendorNamesResponse[]>(null);

  constructor (
    private readonly http: HttpClient,
    private readonly errorHandler: CogErrorHandlerService,
    private readonly config: ConfigDataService,
    private readonly storage: StorageService
  ) {
    this._config = this.config.getConfig();
  }

  // loads code decode value from common-ref api
  loadUxCommonRefData(prefferedLocale: string) {
    try {
      let defaultLocale = 'EN';
      let prefLang = prefferedLocale.toLocaleUpperCase();
      let lcodeListsRequest = [
        {
          'categoryGroupType': 'Application', 'source': 'CBD',
          'codelist': [
            {'categoryId': 'CurrencyCode', 'localeId': defaultLocale},
            {'categoryId': 'CountryId', 'localeId': 'ENGLISH'}
          ]
        },
        {
          'categoryGroupType': 'Application', 'source': 'MDS',
          'codelist': [
            {'categoryId': 'BroadviewMinorLOB', 'localeId': defaultLocale},
            {'categoryId': 'BroadviewMajorLOB', 'localeId': defaultLocale},
            {'categoryId': 'PrimaryVendorCategory', 'localeId': prefLang},
            {'categoryId': 'RegionCode', 'localeId': defaultLocale}
          ]
        },
        {
          'categoryGroupType': 'Application', 'source': 'CVM',
          'codelist': [
            {'categoryId': 'InstructionCode', 'localeId': prefLang},
            {'categoryId': 'VendorType', 'localeId': prefLang},
            {'categoryId': 'AssignmentVendorTypes', 'localeId': prefLang},
            {'categoryId': 'VendorStatus', 'localeId': prefLang},
            {'categoryId': 'LocalOrTravelGeographicCov', 'localeId': prefLang},
            {'categoryId': 'GenericAssignmentRouting', 'localeId': prefLang},
            {'categoryId': 'CATAgreement', 'localeId': prefLang},
            {'categoryId': 'IsApprovalRequired', 'localeId': prefLang},
            {'categoryId': 'RateCardApplication', 'localeId': prefLang},
            {'categoryId': 'IsVendorEnabledForT360', 'localeId': prefLang},
            {'categoryId': 'IsLetterOfEngagementAvailable', 'localeId': prefLang},
            {'categoryId': 'IsLSAAvailable', 'localeId': prefLang},
            {'categoryId': 'IsSLAavailable', 'localeId': prefLang},
            {'categoryId': 'IsBillingGuidelinesAvailable', 'localeId': prefLang},
            {'categoryId': 'IsPricingScheduleAvailable', 'localeId': prefLang},
            {'categoryId': 'IsLitigationGuidelinesAvailable', 'localeId': prefLang},
            {'categoryId': 'AssignmentReasonCode', 'localeId': defaultLocale},
            {'categoryId': 'T360ReasonCode', 'localeId': defaultLocale},
            {'categoryId': 'T360SpecialPaymentInstructions', 'localeId': defaultLocale},
            {'categoryId': 'Off-PanelAssignmentReasonCodes', 'localeId': prefLang},
            {'categoryId': 'ApproverRejectionReasonCodes', 'localeId': prefLang},
            {'categoryId': 'CancellationReasonCodes', 'localeId': prefLang},
            {'categoryId': 'VendorDeclintureReasonCodes', 'localeId': prefLang},
            {'categoryId': 'VATResponsibility', 'localeId': prefLang},
            {'categoryId': 'RejectDatesOfService', 'localeId': prefLang},
            {'categoryId': 'CountryPanelName', 'localeId': defaultLocale},
            {'categoryId': 'VendorServiceCategory', 'localeId': prefLang},
            {'categoryId': 'PanelSegmentation', 'localeId': defaultLocale},
            {'categoryId': 'AssignmentType', 'localeId': defaultLocale},
            {'categoryId': 'RepresentationType', 'localeId': prefLang},
            {'categoryId': 'FeeArrangement', 'localeId': prefLang},
            {'categoryId': 'VendorAssignmentStatus', 'localeId': prefLang},
            {"categoryId": 'ResponsibleBusinessUnit', 'localeId': prefLang},
            {"categoryId": 'ClaimsDelegatedAuthority', 'localeId': prefLang},
            {"categoryId": 'ClaimsDelegatedAuthLevel', 'localeId': prefLang},
            {"categoryId": 'DelegatedAuthHandleComplaint', 'localeId': prefLang},
            {"categoryId": 'LossFundProvided', 'localeId': prefLang},
            {"categoryId": 'AccessToClaimsSystem', 'localeId': prefLang},
            {"categoryId": 'CGMBusinessFlag', 'localeId': prefLang},
            {"categoryId": 'VMRiskAssessmentRating', 'localeId': prefLang},
            {"categoryId": 'TPCRRating', 'localeId': prefLang},
            {"categoryId": 'LastAuditRating', 'localeId': prefLang},
            {"categoryId": 'CustomerCommChannel', 'localeId': prefLang},
            {"categoryId": 'LastAuditRating', 'localeId': prefLang},
            {"categoryId": 'InScopeAssignment', 'localeId': prefLang}
          ]
        }];
      let cacheLang: string = this.storage.getItem(AppConstants.STORAGE_KEY_PREFERRED_LANGUAGE, StorageLocation.Session);
      if (cacheLang !== prefLang) {
        this.http.post<ICodeList[]>(`${this._config.apiUrl.baseApiUrlAppCommonRef}/${ApiUrls.GetExpCodeLists}`, lcodeListsRequest).subscribe({
          next: (result) => {
            this._commUxCodeDecodeValues.next(result);
          },
          error: (err) => {
            this.errorHandler.handleError(err);
          }
        })
      }
      this.storage.setItem(AppConstants.STORAGE_KEY_PREFERRED_LANGUAGE, prefLang, StorageLocation.Session);
    } catch (err) {
      this.errorHandler.handleError(err);
    }
  }

  // loads the vendor names for auto-complete text boxes.
  private loadVendorNames(): Observable<IVendorNamesResponse[]> {
    try {
      let comercialAndGroupVendorSearchReq: IGroupAndCommercialVendorSearchRequest = {
        country: [], region: [], groupReportingVendorName: ''
      };
      return this.http.post<IVendorNamesResponse[]>(`${this._config.apiUrl.baseApiUrl}/${ApiUrls.LoadVendorNames}`, comercialAndGroupVendorSearchReq)
        .pipe(tap(r => {
          this._vendorNames.next(r);
        }));
    } catch (err) {
      this.errorHandler.handleError(err);
    }
  }

  public loadVendorNamesForSearch(comercialAndGroupVendorSearchReq: IGroupAndCommercialVendorSearchRequest) {
    try {
      return this.http.post<IVendorNamesResponse[]>(`${this._config.apiUrl.baseApiUrl}/${ApiUrls.LoadVendorNames}`, comercialAndGroupVendorSearchReq);
    } catch (err) {
      this.errorHandler.handleError(err);
    }
  }

  public loadVendorCountriesByRegions() {
    try {
      return this.http.get<IVendorCountriesResponse[]>(`${this._config.apiUrl.baseApiUrl}/${ApiUrls.LoadVendorCountry}`);
    } catch (err) {
      this.errorHandler.handleError(err);
    }
  }

  // load dependent code decodes region - country
  private loadRegionCountryDependentCodeDecode(): Observable<IDependantCodeListResponse> {
    const req = {
      "parentCategory": {
        "source": "MDS",
        "localeId": "EN",
        "codeCategory": "RegionCode"
      },
      "dependantCategory": {
        "source": "CBD",
        "localeId": "ENGLISH",
        "codeCategory": "CountryId"
      }
    };
    return this.http.post<IDependantCodeListResponse>(`${this._config.apiUrl.baseApiUrlAppCommonRef}/${ApiUrls.GetDepandantCodeLists}`, req)
      .pipe(tap(result => {
        this._depRegCountryCodeList.next(result);
      }));
  }

  // load dependent code decodes major lob - broad view lob
  private loadMlobBlobDependentCodeDecode(): Observable<IDependantCodeListResponse> {
    const req = {
      "parentCategory": {
        "source": "MDS",
        "localeId": "EN",
        "codeCategory": "BroadviewMajorLOB"
      },
      "dependantCategory": {
        "source": "MDS",
        "localeId": "EN",
        "codeCategory": "BroadviewMinorLOB"
      }
    };
    return this.http.post<IDependantCodeListResponse>(`${this._config.apiUrl.baseApiUrlAppCommonRef}/${ApiUrls.GetDepandantCodeLists}`, req)
      .pipe(tap(result => {
        this._depMlobBlobCodeList.next(result);
      }));
  }

  // load dependent code primary vendor category - vendor service category
  private loadPvcVscDependentCodeDecode(): Observable<IDependantCodeListResponse> {
    const req = {
      "parentCategory": {
        "source": "MDS",
        "localeId": 'EN',
        "codeCategory": "PrimaryVendorCategory"
      },
      "dependantCategory": {
        "source": "CVM",
        "localeId": 'EN',//pl.toLocaleUpperCase(),
        "codeCategory": "VendorServiceCategory"
      }
    };
    return this.http.post<IDependantCodeListResponse>(`${this._config.apiUrl.baseApiUrlAppCommonRef}/${ApiUrls.GetDepandantCodeLists}`, req)
      .pipe(tap(result => {
        this._depPvcVscCodeList.next(result);
      }));
  }

  // load dependent code primary vendor category - vendor service category
  private loadCountCountPanDependentCodeDecode(): Observable<IDependantCodeListResponse> {
    const req = {
      "parentCategory": {
        "source": "CBD",
        "localeId": "ENGLISH",
        "codeCategory": "CountryId"
      },
      "dependantCategory": {
        "source": "CVM",
        "localeId": "EN",
        "codeCategory": "CountryPanelName"
      }
    };
    return this.http.post<IDependantCodeListResponse>(`${this._config.apiUrl.baseApiUrlAppCommonRef}/${ApiUrls.GetDepandantCodeLists}`, req)
      .pipe(tap(result => {
        this._depCountCountPanCodeList.next(result);
      }));
  }

  getCCAppCrossWalks(source: string, target: string, codelist: string[]): Observable<ICrossWalksResp> {
    let lcodeListsRequest = {
      source: source,
      target: target,
      codeList: codelist,
      localeId: 'EN'
    };
    return this.http.post<ICrossWalksResp>(`${this._config.apiUrl.baseApiUrlAppCommonRef}/${ApiUrls.GetCrossWalks}`, lcodeListsRequest);
  }

  getVendorNames(): Observable<IVendorNamesResponse[]> {
    let o;
    if (this._vendorNames.getValue()) {
      o = this._vendorNames.asObservable();
    } else {
      o = this.loadVendorNames();
    }
    return o;
  }

  getRegCountry(): Observable<IDependantCodeListResponse> {
    let o;
    if (this._depRegCountryCodeList.getValue()) {
      o = this._depRegCountryCodeList.asObservable();
    } else {
      o = this.loadRegionCountryDependentCodeDecode();
    }
    return o;
  }

  getMlobBlob(): Observable<IDependantCodeListResponse> {
    let o;
    if (this._depMlobBlobCodeList.getValue()) {
      o = this._depMlobBlobCodeList.asObservable();
    } else {
      o = this.loadMlobBlobDependentCodeDecode();
    }
    return o;
  }

  getPvcVsc(): Observable<IDependantCodeListResponse> {
    let o;
    if (this._depPvcVscCodeList.getValue()) {
      o = this._depPvcVscCodeList.asObservable();
    } else {
      o = this.loadPvcVscDependentCodeDecode();
    }
    return o;
  }

  getCountryCountryPan(): Observable<IDependantCodeListResponse> {
    let o;
    if (this._depCountCountPanCodeList.getValue()) {
      o = this._depCountCountPanCodeList.asObservable();
    } else {
      o = this.loadCountCountPanDependentCodeDecode();
    }
    return o;
  }
}
