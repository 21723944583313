import { Injectable } from '@angular/core';
import { StorageLocation, StorageUtilsService } from '@chubb-auth/types';

@Injectable({
  providedIn: 'root'
})
export class StorageService extends StorageUtilsService {

  constructor () {super();}

  getTypedItem<T>(key : string, location ?: StorageLocation) : T {
    const v = this.getItem(key, location);
    return <T> JSON.parse(v);
  }
}
